export const ANARCHISTPHILLY = [
  {
    title: "Philly Anti-Capitalist",
    summary: "Philly Anti-authoritarian News since 2014. Post events, also. Maintains a nice list of Philly local groups and resources.",
    mainLink: "https://phlanticap.noblogs.org",
    subLink: "https://phlanticap.noblogs.org/homies",
    suggestedFollow: "Check site weekly"
  },
  {
    title: "Lancaster Avenue Autonomous Space",
    summary: "“A community center for radical media and organizing located in the Belmont neighborhood of West Philadelphia.” Radical zine/book library and social center. 20 years in 2025! Monthly free market, food distro, food share, SRA hoagie distro, workshops, movie nights, rooftop shows, WRC community fridge, etc..",
    mainLink: "https://lavazone.org",
    insta: "https://www.instagram.com/lavaspacephl",
    suggestedFollow: "Insta"
  },
  {
    title: "Neighborhood Bike Works",
    summary: "Community bike shop. Learn to repair shop nights, cheap used bikes, cheap bike wheels and parts, youth and adult bike repair programs, bike drives, group rides, trans/women/non-binary only open shop nights, etc..",
    mainLink: "https://neighborhoodbikeworks.org",
    facebook: "https://www.facebook.com/NBWPhilly",
    insta: "https://www.instagram.com/neighborhoodbikeworks",
    suggestedFollow: "Insta (more updated)"
  },
  {
    title: "Iffy Books",
    summary: "“Iffy Books offers a small selection of books and zines on hacking, free culture, gardening, and adjacent topics.” Workshops (ex. ffmpeg Basics, Bleeding Control Basics), meetups (ex. 2600), maker fairs, open mic, speakers, etc.",
    mainLink: "https://iffybooks.net",
    insta: "https://www.instagram.com/iffybooks",
    suggestedFollow: "Site calendar monthly check and Insta",
  },
  {
    title: "Wooden Shoe Books",
    summary: "“Collectively run by volunteers since 1976. Anarchist & Radical Books | Periodicals | Records.“ Best place for local zines and peeping flyers on their bulletin board. Volunteer run with shifts open.",
    mainLink: "https://woodenshoebooks.org",
    facebook: "https://www.instagram.com/thewoodenshoebooks",
    insta: "https://www.facebook.com/WoodenShoeBooks",
    suggestedFollow: "FB + check site calendar monthly"
  },
  {
    title: "O.R.C.A.",
    summary: "“Social space in SW Philly for anarchists and their radical friends. Film screenings, discussion groups, workshops, book talks, available as a meeting space for rads groups. Born from a desire to see more physical spaces for radicals from a variety of tendencies to collide and scheme with one another.“ Opened Feb 2024.",
    mainLink: "https://orcaphilly.noblogs.org",
    twitter: "https://twitter.com/orcaphilly",
    suggestedFollow: "Twtter, or occassional check site for events.",
  },
  {
    title: "Books Through Bars Philadelphia",
    summary: "“Books Through Bars has been sending free books to incarcerated people in six mid-Atlantic states for over 30 years. All books donated. One of the most important tasks our volunteers do is carefully reading the letters we receive, picking books for each request from our library, and invoicing and packing the books.“",
    mainLink: "https://www.booksthroughbars.org",
    facebook: "https://www.facebook.com/booksthroughbars",
    insta: "https://www.instagram.com/btb_philly",
    suggestedFollow: "FB or Insta",
  },
  {
    title: "Anarchist Black Cross Philadelphia",
    summary: "“ABC chapters around the world autonomously support people who are imprisoned for their thoughts and actions for justice and freedom from oppression. Philly ABC reformed in August of 2017.“ Letter writing, book releases, ann. Running Down the Walls, etc.",
    mainLink: "https://phillyabc.org",
    insta: "https://www.instagram.com/philly_abc",
    facebook: "https://kolektiva.social/@phillyabc",
    suggestedFollow: "Insta or Mastadon",
  },
  {
    title: "West Philadelphia Food Not Bombs",
    summary: "Hot meals Fridays 4:30pm at Malcolm X Park. Food pickup for cook 8-9am (drivers needed).",
    insta: "https://www.instagram.com/foodnotbombswestphilly",
    suggestedFollow: "None (social not updated)",
  },
  {
    title: "South Philadelphia Food Not Bombs",
    summary: "“Saturdays Hot Meals @ 3pm. Fridays Fresh Produce @ 8am. Mifflin Square Park (Ritner and 5th).“ Very active. Food pickup drivers needed.",
    insta: "https://instagram.com/foodnotbombs.southphilly",
    suggestedFollow: "Insta",
  },
  {
    title: "Food Not Bombs Solidarity",
    summary: "“FNB Solidarity chapter serving the West Philly Community. 5pm Fridays food distro @ 60th and Market El Stop.“ Also distros to Community Frig at 52/Sansom, St. Columba Mens Shelter, and Nizeh Morris Trans Home.",
    facebook: "https://www.facebook.com/FoodNotBombsSolidarity",
    suggestedFollow: "Facebook",
  },
  {
    title: "North Philly Food Not Bombs",
    summary: "“🏴 We are North Philadelphia Food Not Bombs 🏴 Sundays! - relentlessly sharing resources, support, hot meals and spreading sweet Anarchy since 1999“",
    insta: "https://www.instagram.com/npfnb",
    suggestedFollow: "Insta",
  },
  {
    title: "South Philly Punks With Lunch",
    summary: "“Punks With Lunch is a non-profit organization run by volunteers dedicated to building a community by providing life-saving services, along with harm reduction resources to underserved and marginalized individuals.“ Serves food @ Broad/Snyder @ 1:30pm Sundays & Wednesdays, plus 1:30pm Friday @ Mifflen Square Park. Also, narcan, testing strips, hygiene supplies, clothes, etc..",
    mainLink: "https://punkswithlunch.com",
    facebook: "https://www.facebook.com/PunxisSupport",
    insta: "https://www.instagram.com/south.philly.punkswithlunch",
    suggestedFollow: "Insta (more updated than FB)",
  },
  {
    title: "Homies Helping Homies",
    summary: "“Homies Helping Homies (HHH) is a collective mutual aid initiative with the simple goal of improving the quality of life of the communities we serve in Point Breeze Philadelphia and beyond.“ Monday food & health product distros, community fridge, community photo days, HHH radio, coat drives, and more.",
    mainLink: "https://www.homieshelpinghomies.com",
    facebook: "https://www.facebook.com/homies.helping.homies.phl",
    insta: "https://www.instagram.com/homies.helping.homies",
    suggestedFollow: "Insta or FB",
  },
  {
    title: "Spiral Q",
    summary: "Since 1996. Non-profit PoC lib group. “Spiral Q builds strong and equitable communities characterized by creativity, joy, can-do attitudes.” Community art builds, puppet shows, gallery tours, Annual Blankenburg Parade, Peoplehood Parade and Pageant, workshops, protests, performances, etc.",
    mainLink: "https://www.spiralq.org",
    facebook: "https://www.facebook.com/spiralq",
    insta: "https://www.instagram.com/spiralq",
    suggestedFollow: "Insta (more updated)",
  },
  {
    title: "West Philly Tool Library",
    summary: "A library for tools. Spring tool sale. Classes (ex. DryWall Repair, Plumbing Repair, Sewing Machine Basics, Tools and Skills 101, Home Electronic Repair, etc.). $20 ann. membership. ",
    mainLink: "https://westphillytools.org",
    facebook: "https://www.facebook.com/westphillytools",
    insta: "https://www.instagram.com/westphillytoollibrary",
    suggestedFollow: "Site calendar monthly check and Insta (more updated)",
  },
  {
    title: "Anathema",
    summary: "“A Philadelphia Anarchist Periodical.“ Since 2012!",
    mainLink: "https://anathema.noblogs.org",
    subLink: "https://anathema.noblogs.org/old-issues",
    suggestedFollow: "Read online or check for print versions at Wooden Shoe Books",
  },
  {
    title: "South Philly Autonomous Cinema",
    summary: "Shows regular radical and queer cinema, at different venues and community spaces, usually once or twice a month. Started Dec, 2022. Also run Philm Club indie screenings newsletter.",
    mainLink: "https://cinemaphilly.com",
    insta: "https://www.instagram.com/cinema_philly",
    twitter: "https://twitter.com/cinema_philly",
    suggestedFollow: "Insta",
  },
  {
    title: "Hudson Valley Earth First!",
    summary: "Hosted National EF! Rody (RRR) July 2-9, 2024 in so-called northeastern Pennsylvania. Also hosted EF! RRR in 2018 in so-called Ohio. Not very active online, but active on the ground.",
    mainLink: "https://earthfirstjournal.news",
  },
  {
    title: "Community Fridges in Philadelphia",
    summary: "A list of community fridges in Philadelphia, as lasted updated in February 2024",
    mainLink: "https://www.inquirer.com/food/community-fridges-philadelphia-locations.html",
  },
  {
    title: "Making Worlds Cooperative Bookstore and Social Center",
    summary: "“We are a nonprofit cooperative movement-based bookstore and social center located in West Philadelphia with roots in independent book publishing, education, community media and journalism, and organizing. “ Documentaries, speakers, reading groups, teach-ins, mixers, open mic, etc.",
    mainLink: "https://www.makingworldsbooks.org",
    subLink: "",
    facebook: "https://www.facebook.com/makingworldsbooks",
    insta: "https://www.instagram.com/makingworldsbooks",
    suggestedFollow: "Check site now and then for events",
  },
  {
    title: "Running Down the Walls",
    summary: "“Since 1999, prisoners and supporters throughout North America have participated in the annual event known as Running Down the Walls. This is a non-competitive 5K run/jog/walk/roll to raise awareness and funds for political prisoners. Over the years, we have raised thousands of dollars and lots of awareness.“ September, anually.",
    mainLink: "https://www.abcf.net/rdtw",
    twitter: "https://twitter.com/phillyabc",
    suggestedFollow: "Watch Philly ABC Twitter for ann. date",
  },
  {
    title: "Free Food & Meal Finder",
    summary: "“Get free food, nutrition services, and public benefits.“ A big Philly dot marker map of spots for free food sites, general meal sites, older adult meal sites, student meal sites, and food assistance and benefits sites. Everything from churches, to schools, to libraries, to rad kitchens.",
    mainLink: "https://www.phila.gov/food",
  },
  {
    title: "Really Really Free Market",
    summary: "“Happening every last Saturday of the Month @ Malcolm X Park, corner of Larchwood & 52nd Street, promoting a gift economy through mutual aid 🖤“",
    insta: "https://www.instagram.com/philly.freemarket",
    suggestedFollow: "Insta (can be cancelled, if bad weather)",
  },
  {
    title: "West Philly Free Resources Map",
    summary: "A map of free stuff across West Philly. Free piles, free tables, food fridges, food pantries, etc.",
    mainLink: "https://www.google.com/maps/d/viewer?mid=1c1X8gigZGPn1MhBXBEVYjX6oGgGKV3Xg",
  },
  {
    title: "Firefly Action Medical",
    summary: "“Philly street medics. Collective since 2014. Provide medical support at free health clinics, direct actions, activist camps, street protests, and disaster relief mobilizations.“ Give 20 hour street medic training courses.",
    mainLink: "https://fireflyactionmedical.wordpress.com",
    insta: "https://www.instagram.com/fireflyactionmedical",
    suggestedFollow: "Insta (training courses book fast)"
  },
  {
    title: "fragments.215",
    summary: "The Philly branch of Spirit of May 28th, a radical faction working to keep the flames and passion of the 2020 uprisings still burning. Posts mostly related to ongoing anarchist struggles; Cop City, political prisoner support, local anarchist events, etc.",
    mainLink: "https://www.sm28.org",
    insta: "https://www.instagram.com/fragments.215",
    suggestedFollow: "Insta",
  },
  {
    title: "Here & Now Zines",
    summary: "Used to be a zine distro, that would distro at events like Anarchist Skill Share, now an “Anarchist news media and happenings hype account.“ Posts on Philly anarchist news, film screenings, events, discussion groups, teach-ins, fundraisers, calls for action, solidarity vigals, local anarchist struggles, action report backs, etc.",
    insta: "https://www.instagram.com/hereandnowzines",
    suggestedFollow: "Insta",
  },
  {
    title: "Up Against the Law Legal Collective",
    summary: "“A Philadelphia based collective of volunteers who support activists by helping them know their rights, providing Legal Observers at protests, ensuring protesters don’t get lost in the legal system during actions, and connecting them with free legal support after actions.“ Arrest hotline: (484) 758-0338.",
    mainLink: "https://upagainstthelaw.org",
    facebook: "https://www.facebook.com/UpAgainstTheLaw",
    insta: "https://www.instagram.com/upagainstthelaw",
    twitter: "https://twitter.com/UpAgainstTheLaw",
    suggestedFollow: "Twitter (most updated)"
  },
  {
    title: "Philly Anti-Repression Fund",
    summary: "“A resource for people facing state repression and for fighting against systemic oppression and exploitation. We support those the state criminalizes for activities performed that threaten to change the status quo.“ Bonds and legal fee assistance for political prisoners.",
    mainLink: "https://phillyantirepression.wordpress.com",
    twitter: "https://twitter.com/phlbailfund",
    suggestedFollow: "Twitter (posts Philly rad news and events)",
  },
  {
    title: "Viscera Print Goods and Ephemera",
    summary: "“Roving anarchist distro and bazaar now based in Philadelphia (formerly Providence, RI). Seller of anarchist print goods and ephemera – books, pamphlets, oddities and more.“ Also, book readings and zine discussions, documentaries, etc.. A new reading each month since 2017.",
    mainLink: "https://viscerapvd.wordpress.com",
    subLink: "https://ni.hil.ist/@Viscera",
    insta: "https://www.instagram.com/viscera_pvd",
    suggestedFollow: "Mastadon or Twitter",
  },
  {
    title: "BSides Philadelphia",
    summary: "“BSidesPhilly 2024 is Philadelphia’s premier cybersecurity conference, bringing together security professionals, hackers, researchers, and enthusiasts.“ First weekend of Dec, in last couple years.",
    mainLink: "https://bsidesphilly.org",
    insta: "https://www.instagram.com/bsidesphilly",
    suggestedFollow: "Check for annual event date in early winter",
  },
  {
    title: "NYC Anarchist Bookfair",
    summary: "“The NYC Anarchist Book Fair was founded in 2007. It brings around two thousand anarchists, anarchy supporters and curious together in New York City (on Lenape land) every year since its foundation.“ In September, usually.",
    mainLink: "https://anarchistbookfair.net",
    insta: "https://www.instagram.com/nycabf",
    suggestedFollow: "Insta + check site in mid-late summer to confirm annual event date",
  },
  {
    title: "It's Going Down: Upcoming Events",
    summary: "Anarchist events nationwide, such as June 11th events, Earth First! Rondy, anarchist bookfairs, anarchist tech conferences, etc., including events in so-called Philly and Philly region.",
    mainLink: "https://itsgoingdown.org/upcomingevents",
    suggestedFollow: "Check now and then for new events"
  },
]

export const ANIMEPHILLY = [
  {
    title: "Philadelphia Animation Society",
    summary: "Monthly in-person meetings at FU church, 5pm to 10pm, every 2nd Saturday of the month. 42 years and running!!! Needs new members! Please attend!",
    facebook: "https://www.facebook.com/PhiladelphiaAnimationSociety",
    suggestedFollow: "Facebook",
  },
  {
    title: "Philadelphia Anime and Gaming Society",
    summary: "General Philly Anime and table top gaming FB group. Hosts a lot of events, especially table top meetups.",
    facebook: "https://www.facebook.com/groups/PhiladelphiaAnimeAndGamingSociety",
    suggestedFollow: "Facebook",
  },
  {
    title: "Fan Expo Philadelphia",
    summary: "Convention. Late spring each year. Traveling con held in many cities. General con (no big anime con in Philly). “Join tens of thousands of fans who are just like you and experience the ultimate playground for comics, sci-fi, horror, anime, and gaming.”",
    mainLink: "https://fanexpohq.com/fanexpophiladelphia",
    facebook: "https://www.facebook.com/FANEXPOPhiladelphia",
    insta: "https://www.instagram.com/fanexpophiladelphia",
    suggestedFollow: "Check site for con date and early bird ticket sales date at start of spring",
  },
  {
    title: "Philly Otaku",
    summary: "Group of otaku in Philly who throw events, host meetups, etc.. Small conventions, sit and draws, cosplay photoshoots, dance parties, most often at Cherry Street Pier. OtakuCon @ Cherry Street Pier, mid spring, annually",
    facebook: "https://www.facebook.com/phillyotaku",
    insta: "https://www.instagram.com/phillyotaku",
    suggestedFollow: "Insta",
  },
  {
    title: "Chibi Café",
    summary: "1 hour N of Philly. “Chibi Café is founded by otakus for otakus! We carry a variety of activities from old school arcades and table top gaming, to a manga library.” Hosts events (ex. D&D night, drawing classes, board game nights, micro cons).",
    mainLink: "https://www.chibicafeco.com",
    facebook: "https://www.facebook.com/chibicafeco",
    insta: "https://www.instagram.com/chibi_cafe_co",
    suggestedFollow: "Check site once a month for calendar",
  },
  {
    title: "J1-Con",
    summary: "“Anime & Gaming Expo. Are you a fan of animation, cosplay, gaming (tabletop, card and console), artists, vendors, panels, and live music? You’ve come to the right convention! 13 YEARS! Longest running black owned anime convention in the USA!“ Usually first weekend of November. A few thousand attendees, generally.",
    facebook: "https://www.facebook.com/j1con",
    insta: "https://www.instagram.com/j1con",
    suggestedFollow: "Check site early fall for annual con date",
  },
  {
    title: "Anime NYC",
    summary: "NYC Convention each year in late summer. Huge attendance, with 100k people in 2024, making it one of the largest cons in America. Newer con (only since 2017), but still had 20k at first con, too. Weekend badges sell out FAST.",
    mainLink: "https://animenyc.com",
    subLink: "https://www.facebook.com/animenyc",
    facebook: "https://www.facebook.com/groups/animenycon",
    insta: "https://www.instagram.com/animenyc",
    suggestedFollow: "Check site for annual con date at start of summer",
  },
  {
    title: "Puchicon",
    summary: "“Anime, Gaming, Comics Convention run by a Latina mom with ADHD.” Small con, a few 1-3k attendees. In October, annually, 2 hrs N of Philly, in Fort Lee, NJ.",
    mainLink: "https://www.puchicon.com",
    facebook: "https://www.facebook.com/puchicon",
    insta: "https://www.instagram.com/puchicon",
    suggestedFollow: "Check FB occasionally, for local events and con dates",
  },
  {
    title: "Otakon",
    summary: "DC Convention (3 hrs out of Philly), usually in late July or early August. Old as hell, started in 1994. 42k attendance in 2022. A good con for older anime fans as many attendees been attending for many years.",
    mainLink: "https://www.otakon.com",
    facebook: "https://www.facebook.com/Otakon",
    insta: "https://www.instagram.com/otakonpics",
    suggestedFollow: "Check site mid spring for badge sales date",
  },
];

export const CLOSED = [
  {
    title: "The Secret Garden",
    summary: "“coming Fall 2023 to Carlisle St.“ Basement spot. Had a single show in Sept 2023 and then was never seen again.",
    insta: "https://www.instagram.com/secretgardenphl/",
  },
  {
    title: "House Show State College",
    summary: "On indefinite hiatus starting August 2024. “state college diy shows, updated every wednesday, band interest form in linktree.” Run by the same folk as houseshowphilly, the Kissfit Collective.",
    insta: "https://www.instagram.com/houseshowstatecollege/",
  },
  {
    title: "Dragon's Den",
    summary: "Insta vanished. “Account made to promote house shows around Drexel University and in Philly.” Show aggregator.",
    insta: "https://www.instagram.com/_drexeldragons_den/",
  },
  {
    title: "House Show Philly",
    summary: "Vanished start of 'Nov 2024, seemingly due to being unable to dedicate time to run the Insta anymore. “Upcoming philly house show masterlist, woman owned (she/her), updated every wednesday.“ Run by Kissfit Collective. Also organizes shows. THE place for checking upcoming DIY shows. A great testament to how active Philly DIY is.",
    insta: "https://www.instagram.com/houseshowphilly/",
    subLink: "https://www.instagram.com/kissfitcollective/",
  },
  {
    title: "A-Space Anarchist Community Center ",
    summary: "Closed Winter 2023 (due to covid, then collective members moving away, now all Books Through Bars space). From when open: “The building that holds the A-Space is part the Life Center Association (LCA), a land trust. “4722” was the second house to be bought by the LCA in 1973. It holds 2 apartments, the A-Space, and Books Through Bars.”",
    mainLink: "https://phillyaspace.com",
    facebook: "https://www.facebook.com/A-Space-Anarchist-Community-Center-30987050865",
    twitter: "https://twitter.com/narchyf",
  },
  {
    title: "Traitor Joe’s",
    summary: "“West Philly house show vessel. 2019-2022. 🏴‍☠️We appreciate your interest but we are a DEAD VENUE 🏴‍☠️”",
    facebook: "https://www.facebook.com/traitorjoesphl",
    insta: "https://www.instagram.com/traitorjoeswestphilly",
  },
  {
    title: "The Alley",
    summary: "“philly diy venue. literally an alley.” House venue with shows behind the house. Infrequent shows. Closed early 2023.",
    insta: "https://www.instagram.com/thealleyphila",
  },
  {
    title: "The Mansion",
    summary: "North Philly house venue near Temple. Pretty active. Also did collaborations with other venues. Final show 04/29/2023.",
    insta: "https://www.instagram.com/themansionphilly",
  },
  {
    title: "Save the Meadows at FDR Park",
    summary: "A movement that ran in 2022 to try and stop the FDR park meadows from being cut down and turned into a parking lot and tennis courts. Failed to stop the destruction, but some cool shit went on as part of this.",
    mainLink: "https://www.savethemeadows.com",
    facebook: "https://www.facebook.com/stmatfdr",
    insta: "https://www.instagram.com/savethemeadowsfdr",
  },
  {
    title: "Literary Philly",
    summary: "General literature and writing events calendar. No longer maintained with events no longer really posted to it.",
    mainLink: "https://literaryphilly.org/events/list",
  },
  {
    title: "The Room at Harper’s Pub.",
    summary: "“Music venue in Clementon NJ.” Lots of local bands. Metal, punk, hardcore, alt. Closed Oct' 22 and turned into Harper’s Discount Liquors, under new owners.",
    mainLink: "https://harperspubnj.com",
    facebook: "https://www.facebook.com/TheRoomatHarpers",
    insta: "https://www.instagram.com/theroomatharpers",
  },
  {
    title: "Big Hit",
    summary: "Philly show organizer. Put together shows at DIY spaces around Philly. Last booking was Nov 2022.",
    insta: "https://www.instagram.com/bighitphilly",
  },
  {
    title: "Adonis Shows",
    summary: "Booker and organizer. First show and last show was July 2022.",
    insta: "https://www.instagram.com/adonis_shows",
  },
  {
    title: "Sunflower Philly",
    summary: "“community-based organization focused on providing access to art, music & sustainable resources through a curated series of events and programs in North Philadelphia.” Community garden. Graffiti walls. Shows and events outside....Gentrified into non-existence in 2023 by apartment developers, upon which the historic graffiti walls were demolished to make room for more Kensington yuppies.",
    mainLink: "https://www.sunflowerphilly.org",
    facebook: "https://www.facebook.com/sunflowerphilly",
    insta: "https://www.instagram.com/sunflowerphilly",
  },
  {
    title: "The Tooth Fairy Collective",
    summary: "“women led music & arts collective.” Threw rare shows at local venues. Vanished mid fall 2023.",
    insta: "https://www.instagram.com/toothfairycollective",
  },
  {
    title: "Hang Free",
    summary: "May and September. “Free Biannual Parties held in Philadelphia's Fairmount Park with DJ's spinning Electronic Music!” Some old fam shit, going on since 1998. Last one was in May 2021. Some organizer still maintains site, but no sign of event coming back, now, either.",
    mainLink: "http://www.hangfree.org",
  },
  {
    title: "Dobb’s on South",
    summary: "South Philly dive bar. Local music. Open mic weds. Jam nights. Closed early 2023.",
    facebook: "https://www.facebook.com/dobbsonsouth/ ",
    insta: "https://www.instagram.com/dobbsonsouth",
  },
  {
    title: "Philadelphia Environmental Film Festival",
    summary: "Usually was held mid spring. Was run by Philadelphia Film Society. Ran from 2017 to 2022, but then suddenly vanished in 2023.",
    mainLink: "https://www.onelittleearth.org/peff/?fbclid=IwAR2QNQwZJYsCpqWIwY_DMmkjJkK7yCyB_3fCW2_JqcPCLIX3RcHdbwxv18Q",
  },
  {
    title: "The Trash Heap",
    summary: "Closed West philly house venue. A bit of an older spot. Shows on a stage in backyard or in basement. Most recent show late May ‘22.",
    subLink: "https://www.youtube.com/user/Ntwell718",
    insta: "https://www.instagram.com/the.trash.heap",
  },
  {
    title: "The Trashboat",
    summary: "North Philly house venue. Music and arts. Started Nov '21, with last show April 22.",
    insta: "https://www.instagram.com/trashboatphl",
  },
  {
    title: "The Haw Den",
    summary: "“West philly house venue and art space.” Also throws warehouse events. Opened late '21. Final show Aug '23. Were going to open a larger venue (MERCURY7), in 11/2023, but it never went through.",
    insta: "https://www.instagram.com/haw_den.phl",
  },
  {
    title: "The Tusk",
    summary: "Philly rock and roll bar with a lot of local bands. Center Philly. “We feature live music, burlesque, art shows and rock and roll djs.”",
    facebook: "https://www.facebook.com/TheTuskPhilly",
    insta: "https://www.instagram.com/explore/tags/thetuskphilly",
  },
  {
    title: "Fear and Loathing Philly",
    summary: "House venue. “West Philly. We love cats. Started Nov ‘22. Closed Feb '24.'",
    insta: "https://www.instagram.com/fearandloathingphilly/ ",
  },
  {
    title: "The Crawlspace",
    summary: "North Philly house venue. Closed Aug 2022. “AI generated mosh pit in north philly.”",
    insta: "https://www.instagram.com/thecrawlspacephl ",
  },
  {
    title: "The Echo House",
    summary: "“We have house shows. Temple Area.” Few shows, then no shows since Nov '22.'",
    insta: "https://www.instagram.com/echohousephilly",
  },
  {
    title: "Grouvier Street",
    summary: "“DIY live music venue @ temple u.” Backyard venue. Started Sept’ 21. Last show April 2023.",
    insta: "https://www.instagram.com/grouvierstreet",
  },
  {
    title: "Malcolm Street Basement",
    summary: "“West Philly house venue & practice space” Started spring ‘22. On hiatus since April 2023.",
    insta: "https://www.instagram.com/malcolm_street_basement",
  },
  {
    title: "Bread Box",
    summary: "“Art Gallery. Music venue. Event space.” Opened March 2023, hosted a ton of shows summer 2023, did some makers markets, a fashion market, poetry series, then brutally had their lease terminated in Sept 2023.",
    insta: "https://www.instagram.com/malcolm_street_basement",
    facebook: "https://www.facebook.com/breadboxphilly",
  },
  {
    title:"Sweet Sixteen",
    summary: "South Philly house venue. Basement spot. Opened 2022. Went on hiatus fall 2023.",
    insta: "https://www.instagram.com/sweet16phl"
  },
  {
    title:"West Philly Vault",
    summary: "West Philly house venue. Started mid 2022. A lot of jazz bands. R&B. Hip hop. Halloween show costume contest. Back yard acoustic shows. Final show Sept 2023.",
    insta: "https://www.instagram.com/westphillyvault"
  },
  {
    title: "Treehouse of Horror",
    summary: "“South Philadelphia’s only DIY venue. DM to book.“ Started May 2023. Short lived, doing about 6 shows, before it stopped September 2023.",
    insta: "https://www.instagram.com/treehouseofhorrorphl",
  },
  {
    title: "GRRRLCLIQUE Collective",
    summary: "Short lived electronic music collective that threw raves around Philly in 2023.“held our largest and most successful event on Friday. Hundreds of ravers showed up. The spirit is alive.” Jungle, house, breakbeat, techno, trance, hardcore, footwork, nightwork, etc.",
    insta: "https://www.instagram.com/12.15.15.11",
  },
  {
    title: "Rats Den",
    summary: "Relatively older DIY spot, opened Jan 2020, indoor and outdoor shows, mini music festivals, big Halloween shows. No shows since since Oct. 2023.",
    insta: "https://www.instagram.com/rats_den_official",
  },
  {
    title: "Gilbert's Shoes",
    summary: "“Gilbert's Shoe's is a collectively-run, not-for-profit, community center located at 1652 Ridge Avenue in Francisville, Philadelphia. We are a grassroots, entirely volunteer, project dedicated to both building community and to community organizing, by supporting youth, education, art, culture and other forms of self-determination and self-defense. The community center hosts classes, screenings, music, meetings, performances, workshops, skill-shares and other events.“ Never recovered after covid + surrounding gentrification.",
    mainLink: "http://gilbertshoes.info",
    subLink: "https://www.instagram.com/p/CzZYUQLOmb1/?igshid=MXY3MG5wMzgwdHZtdQ%3D%3D",
    facebook: "https://www.facebook.com/profile.php?id=100079240824087",
    insta: "https://www.instagram.com/explore/locations/270231111/gilberts-shoes",
  },
  {
    title: "Our Philly",
    summary: "A huge 'project of love and obsession' Philly spaces and events site, similar to this one. Lists of local organizations, parks, markets, restaurants, bars, groups, site tools, etc., where list items can be user ranked, by number of 'hearts.' Find a cool bar or restaurant here. Went offline in early 2024.",
    mainLink: "https://www.ourphilly.org/browse",
    subLink: "https://www.ourphilly.org/how-to-find-events-in-philly",
    suggestedFollow: "Check “hot and upcoming” page monthly",
  },
  {
    title: "UArts Radio",
    summary: "“UARTS Radio is an online radio station and blog bringing you the latest in music, culture and events. Supported by the University of the Arts” Shut down with the sudden and unexpected closure of U Arts in summer 2024.",
    mainLink: "http://www.uartsradio.com",
    facebook: "https://www.facebook.com/uartsradioblog",
    insta: "https://www.instagram.com/uartsradio",
  },
  {
    title: "The Palace Jawn",
    summary: "Was a very active South Philly house venue. Closed early 2024.",
    subLink: "https://plazeapp.co/blog/rethinking-the-philly-house-show-with-the-palace",
    insta: "https://www.instagram.com/thepalacejawn",
  },
  {
    title: "The Sankofa House",
    summary: "”Our historic Sankofa House is back! #WestPhilly’s safe/inclusive performing house for curators, performers, bands, DJs and community gatherers.” Hip hop, reggae, afrobeats, dancehall, soca, r&b, art making events, open mic, dance parties, workshops. Shut down by the city spring 2023.",
    facebook: "https://www.facebook.com/HouseOfSankofa",
    insta: "https://www.instagram.com/sankofahousephilly",
  },
  {
    title: "Haus de Jadore",
    summary: "“North Philly House Show Venue. Run by 1 hyperfem & 2 androgynous broads.” Show in late Aug ‘22, then early Oct, but nothing since.",
    insta: "https://www.instagram.com/hausdejadore/ ",
  },
  {
    title: "Haus of Yarga",
    summary: "“Haus venue in west philly”. NOTAFLOF. Basement venue. Lots of punk, hardcore, metal. Closed 'Oct 2024. A terrible loss.",
    facebook: "https://www.facebook.com/hausofyarga",
    insta: "https://www.instagram.com/hausofyarga",
  },
  {
    title: "The Tabernacle (venue)",
    summary: "N Philly house venue. “community based, queer run booking collective and acoustic venue.” Has also hosted DIY Flea Markets. Still books shows, but no longer throws them at house.",
    insta: "https://www.instagram.com/tabernaclephilly",
  },
  {
    title: "Rolling with the Homos",
    summary: "“A queer exc. skate community for all wheels, skill levels, and ages to grow & bond in Philly. Come skate with us! 🏳️‍🌈🛹🛼“ Multiple events per month, sans cold/wet winter months: boarding basics, skate rink meetups, group trail skates, trans skate, bipoc skate, etc..",
    facebook: "https://www.facebook.com/rwthphilly",
    insta: "https://www.instagram.com/rwthphilly",
    suggestedFollow: "Insta",
  },
  {
    title: "Philly Antifa",
    summary: "“An independent Anti-Fascist crew operating in Philadelphia, PA and the surrounding area.  In direct conflict with Racism, Homophobia, Sexism, Anti-Semitism, Islamophobia, Transphobia, and all flavors of Fascism. Anti-authoritarians and anti-nationalist.“ Does not work with the police, state, compromising NGOs, etc.",
    mainLink: "https://phillyantifa.org",
    twitter: "https://x.com/phillyantifa",
    suggestedFollow: "Twitter",
  },
  {
    title: "Goo Lagoon",
    summary: "“North Philly House Show Venue. Started Nov 2023. Hardcore, hardcore punk, screamo, emo, psychedelic, etc.“ Closed Sept. 2024 'due to internal conflicts.'",
    insta: "https://www.instagram.com/goolagoon.phl",
  },
  {
    title: "Philly Fun Guide Playing and Coming Soon Films List",
    summary: "Philly Fun Guide ended mid 2024. Fun is over! Film list was a good list, mostly a collection of stuff going on at different arthouse theaters.",
    mainLink: "https://phillyfunguide.com/film",
    suggestedFollow: "Check monthly for cool films.",
  },
  {
    title: "Underground Arts Monthly Horror Movies",
    summary: "No longer happening. Stopped sometime mid 2024. Had good titles. A lot of classics, cult, and B.",
    mainLink: "https://undergroundarts.org",
    suggestedFollow: "Check site calendar monthly screenings, most often first Monday of the month.",
  },
  {
    title: "Media Fright Fest",
    summary: "Used to be it's own thing, but got combined w/ Media Film Fest. “Horror has a home at Media Fright Fest! We present indie horror films and pop up events throughout the year.“ Run annual horror local film fest in early fall, usually the final weekend of September or first weekend of October.",
    mainLink: "https://www.facebook.com/MediaArtsCouncil",
    facebook: "https://www.mediaartscouncil.org",
    insta: "https://www.instagram.com/mediaartscouncil",
    suggestedFollow: "Check in later summer for festival date.",
  },
  {
    title: "Horror Haven Film Expo",
    summary: "Moved to Indiana. “Experience the spine-chilling allure of Horror Haven Film Expo, a dynamic virtual platform spotlighting terror-inducing films worldwide. Embrace the horror enthusiast community by submitting films of diverse lengths, subgenres, and styles.“ Annual spring online film fest run by folk who do Media Fright Fest.",
    mainLink: "https://hhfilmexpo.com",
    facebook: "https://www.facebook.com/HorrorHavenExpo",
    suggestedFollow: "Check FB early spring for annual date",
  },
  {
    title: "Grounded in Philly",
    summary: "Large scale “use the laws to our advantage” guerilla gardening project. Has turned 30+ vacant lots in SW Philly into guerilla gardens. Based on a “How to Obtain Land Through Adverse Possession” model.",
    mainLink: "https://groundedinphilly.org/",
    subLink: "https://www.axios.com/local/philadelphia/2021/10/06/philadelphia-gardens-ben-miller-peoples-kitchen",
    suggestedFollow: "Contact if want to crack a garden. No real “events” source, otherwise.",
  },
  {
    title: "Philadelphia Foraging Map",
    summary: "An interactive map with over 59k trees listed. Can filter by species, search by loction, and export search results.",
    mainLink: "https://www.opentreemap.org/phillytreemap/map/",
    suggestedFollow: "n/a",
  },
  {
    title: "The Blood Bank",
    summary: "2022-2024. “North Philly Basement Venue.” Started Sept ‘22. Mostly metal and tons of hardcore. Moved out of space spring 2024. A very sad loss.",
    insta: "https://www.instagram.com/bloodbank.phl",
  },
  {
    title: "South Street Fest",
    summary: "As of 2024, no longer occurring...Mid spring, annually.“Spanning 2nd to 8th streets. Live music and performances, international food and drinks, crafts and vendors, Brauhaus Schmitz Manifest, framily friendly kid zones, free comics at Atomic City Comics.“",
    insta: "https://www.instagram.com/southstreetphilly",
    suggestedFollow: "Check annual date in early spring",
  },
  {
    title: "The Haven",
    summary: "N Philly house venue. First show Aug '22...Innactive since winter 2023.",
    insta: "https://www.instagram.com/thehavenphilly/",
  },
  {
    title: "The Location",
    summary: "South Philly 1891 built church turned rehabbed into art lofts. Throw occasional shows...Innactive since summer 2023.",
    insta: "https://www.instagram.com/thelocationphl/",
  },
  {
    title: "BBR Arts Loft",
    summary: "Huge arts loft building just north of Chinatown. DJ sets as well as shows. Run by Big Bad Radio a 24/7 Philly internet radio station. Innactive since spring 2024.",
    insta: "https://www.instagram.com/bigbadradio",
    facebook: "https://www.facebook.com/bigbadradio/"
  },
  {
    title: "The Mosh Pit",
    summary: "“Temple/North Philly pop punk basement shows.” Shows about once a month. Innactive since spring 2023.",
    insta: "https://www.instagram.com/themoshpit1837/",
  },
  {
    title: "Hustle Haus",
    summary: "Innactive S Philly house venue. Don’t have FB or Insta, but post shows in DIY Music Philly! group, and is an active venue. Innactive since late 2022.",
    facebook: "https://www.facebook.com/hashtag/hustlehaus",
    insta: "https://www.instagram.com/explore/tags/hustlehaus/",
  },
  {
    title: "RM Studio",
    summary: "“Manayunk Acoustic House Shows hosted by @ryanmichael.phl.” Started Sept ‘22...Innactive since fall 2023.",
    mainLink: "https://www.instagram.com/rmstudio.phl/",
  },
  {
    title: "Chapter House",
    summary: "“diy live music venue. north philly.” Started Oct ‘22. Only one show, so far, as of 01/2023.",
    insta: "https://www.instagram.com/chapterhousephl/",
  },
  {
    title: "The G-Spot",
    summary: "“trans + queer event space & booking collective based in north philadelphia.” First event Sept ‘22. House venue....Innactive since early 2024.",
    insta: "https://www.instagram.com/gspotphilly/",
  },
  {
    title: "Homesick",
    summary: "”north philly acoustic + multimedia shows welcome home ❤️🧡💛💚💙💜. all types of artists welcome.” Started Feb 2023. Poetry readings, in addition to music...Innactive since early 2024.",
    insta: "https://www.instagram.com/homesickphl/",
  },
  {
    title: "Top Floor Arts",
    summary: "Northwest philly loft venue. ”1,000 sq ft Creative Space. Pop-up shops. Babyshowers. Private Events. Shows. Listening parties. Live food, vendors, tattoos, drinks.” Since Sept 2021. A lot of hip hop and DJ events...Innactive since spring 2023.",
    insta: "https://www.instagram.com/topfloorarts_/",
  },
  {
    title: "The Junction",
    summary: "Basement venue started August 2023. Shows as well as dance parties. Student run and on hiatus during summer...Innactive since spring 2024.",
    insta: "https://www.instagram.com/junctionphl/",
  },
]

export const CYCLING = [
  {
    title: "Cycle Scene PHL",
    summary: "General Philly bike punk group. Good for finding rides, especially ride dates for upcoming Night Cyle, Full Moon Bike Ride, Bike Party, and NW Night rides",
    facebook: "https://www.facebook.com/groups/cyclescenephl",
    insta: "https://www.instagram.com/cyclescenephl",
    suggestedFollow: "Insta (more updated)",
  },
  {
    title: "The Trellis",
    summary: "“This site began in June 2023 and aims to document and elevate Philly biking culture.“ Blog. Posts on rides, news, routes, local cycling teams. Maintains a nice calendar of local rides recurring and annual rides.",
    mainLink: "https://www.thetrellisphilly.com",
    facebook: "https://www.facebook.com/thetrellisphilly",
    insta: "https://www.instagram.com/thetrellisphilly",
    suggestedFollow: "Main blog site",
  },
  {
    title: "Night Cycle Philadelphia",
    summary: "Monthly rides, ~10 miles, at late evening to early night start, usually end of the month, often on a Friday. Ride route and end location vary. Rest & chill spot half-way, then ride to end point.",
    facebook: "https://www.facebook.com/phillybikeride",
    insta: "https://www.instagram.com/nightcycle_phl",
    suggestedFollow: "FB (insta not often updated)",
  },
  {
    title: "Philly Full Moon Bike Ride",
    summary: "“A monthly ride and evening of fun, debauchery, and cycling solidarity as we take in the city streets under the full moon.” Generally meets at art museum.",
    facebook: "https://www.facebook.com/groups/156618137847919",
    suggestedFollow: "FB",
  },
  {
    title: "Philly Bike Party",
    summary: "“Ride bikes! make friends! hop on! Monthly thematic slow cruisin' rides meet at the art museum and make frequent party breaks!”",
    facebook: "https://www.facebook.com/philliebikeparte",
    insta: "https://www.instagram.com/phillybikeparty",
    suggestedFollow: "Insta or FB"
  },
  {
    title: "Northwest Night Rides",
    summary: "“Social bike rides in and around Northwest Philadelphia. Every other Wednesday at 7pm. All are welcome! 🌳🚲🚲🚲🌳“",
    insta: "https://www.instagram.com/nwnightrides",
    suggestedFollow: "Insta",
  },
  {
    title: "Bicycle Club of Philadelphia",
    summary: "Non-profit that hosts group bike rides and allows others to post rides. Usually 10 to 25 miles. Often multiple rides per day. In-the-city and local scenic rides.",
    mainLink: "https://phillybikeclub.org/bcpdo/ride/calendar",
    facebook: "https://www.facebook.com/groups/7137952857",
    insta: "https://www.instagram.com/bicycleclubphl",
    suggestedFollow: "Site calendar check monthly",
  },
  {
    title: "Philadelphia Naked Bike Ride",
    summary: "Get naked and go on a bike ride with other naked and near naked folk. Happens later summer, each summer.",
    mainLink: "https://philadelphianakedbikeride.wordpress.com",
    facebook: "https://www.facebook.com/groups/713317452096574",
    insta: "https://www.instagram.com/philly_naked_bike_ride",
    suggestedFollow: "Check site for ride date in late spring",
  },
  {
    title: "Bicycle Coalition of Greater Philadelphia",
    summary: "Bike streets and trails in Philly, regional bike map, Philly and regional cycling programs, local cycling resources, bike news.",
    mainLink: "https://bicyclecoalition.org",
    facebook: "https://www.facebook.com/bicycle.coalition",
    insta: "https://www.instagram.com/bicyclecoalition",
    suggestedFollow: "Insta or FB",
  },
  {
    title: "Philly Bike Action!",
    summary: "“Safe, protected bike lanes for Philly. We’re committed to the safety, dignity, and convenience of people who ride bicycles in Philadelphia. Visit our website to sign up for our newsletter and our Discord server.“ ",
    mainLink: "https://bikeaction.org",
    insta: "https://www.instagram.com/phlbikeaction",
    facebook: "https://www.facebook.com/p/Philly-Bike-Action-61556592972926",
    suggestedFollow: "Insta",
  },
  {
    title: "Keystone Bicycle Co.",
    summary: "“Keystone Bicycle Co. is a full-service bicycle shop serving a variety of cycling groups in Philadelphia. As a worker-owned cooperative, we practice democratic decision making, collective ownership, and community engagement. KBC aims to be a hub for cycling advocacy and inspiration in Philadelphia.“ Host events such as bike swaps, and overnight bike camping trips, as well as repair and wheel building classes",
    mainLink: "https://keystone.bike",
    facebook: "https://www.facebook.com/keystone.bike",
    insta: "https://www.instagram.com/keystone.bike",
    suggestedFollow: "Check their site calender monthly for events",
  },
  {
    title: "Kensington Derby and Arts Festival",
    summary: "“The Kensington Derby and Arts Fest celebrates the East Kensington neighborhood in Philadelphia, with art, food, music, drinks, and a parade of homemade human-powered sculptures.“ Includes Kinetic Sculpture Derby: DIY constructed human-powered vehicles of all sorts run a 3-mile obstacle laden course through Fishtown and East Kensington, with a final mud pit obstacle at race end. Usually second weekend in May.",
    mainLink: "https://www.kensingtonkineticarts.org",
    facebook: "https://www.facebook.com/kensingtonkineticarts",
    insta: "https://www.instagram.com/kensingtonkineticarts",
    suggestedFollow: "n/a",
  },
  {
    title: "PMA Bike Ride",
    summary: "“The PMA bike ride was started in Philadelphia, PA on June 24th, 2016. We deliver pizza and smiles to the hungry on the streets (bike not needed).“ Join the ride and give some hungry folks some pie.",
    mainLink: "http://www.thepmabikeride.com",
    facebook: "https://www.facebook.com/pmabikeride",
    insta: "https://www.instagram.com/thepmabikeride",
    suggestedFollow: "Insta or FB",
  },
];

export const FILMFESTIVALS = [
  {
    title: "Philadelphia Film Festival",
    summary: "Mid October, annually. 11 days long. Hosted by The Philadelphia Film Society. 33rd year in 2024.",
    facebook: "https://www.facebook.com/PhillyFilm",
    insta: "https://www.instagram.com/phillyfilmfestival",
    mainLink: "https://filmadelphia.org/festival",
    suggestedFollow: "Check site for annual date at the start of fall.",
  },
  {
    title: "Big Picture Film Fest",
    summary: "“Features short films by young aspiring filmmakers (ages 12-24) from across Philadelphia.” Mid summer, annually. Also, youth programs and filmmaker classes.",
    mainLink: "https://www.bigpicturealliance.org/films",
    insta: "https://www.instagram.com/BigPictureAlliance",
    facebook: "https://www.facebook.com/bigpicturealliance",
    suggestedFollow: "Check site for annual date in late spring",
  },
  {
    title: "The Philadelphia Independent Film Festival",
    summary: "Late spring, annually. 18th year in 2025.  “We discover and curate a unique collection of local and global Independent Drama, Documentary, Thriller, Sci-Fi, Political, Horror, Comedy, Music Video, Animation, Web Series, AI, and other thought-provoking films in Philadelphia, PA.“",
    mainLink: "http://pifffilms.tv",
    facebook: "https://www.facebook.com/PhillyFilm",
    insta: "https://www.instagram.com/philaindie",
    suggestedFollow: "Check site for annual date in late winter",
  },
  {
    title: "Blackstar Film Festival",
    summary: "Late summer, annually. “BlackStar Film Festival is an annual celebration of the visual and storytelling traditions of the African diaspora and of global Indigenous communities, showcasing films by Black, Brown and Indigenous artists from around the world.”",
    mainLink: "https://www.blackstarfest.org/festival",
    facebook: "https://www.facebook.com/BlackStarFest",
    insta: "https://www.instagram.com/blackstarfest",
    suggestedFollow: "Check site for annual date in spring.",
  },
  {
    title: "Philadelphia Latino Film Festival",
    summary: "Annual early summer festival. “The Philadelphia Latino Film Festival (PHLAFF) celebrates the richness & diversity of Latino cinema.“",
    mainLink: "https://www.phlaff.org",
    facebook: "https://www.facebook.com/PHLAFF",
    insta: "https://www.instagram.com/phlatinfilmfest",
    suggestedFollow: "Check site early winter for annual date.",
  },
  {
    title: "Philadelphia Asian American Film Festival",
    summary: "Annual festival each fall. “Dedicated to supporting and highlighting the experiences of Asian and Pacific Islander diaspora through creative community-focused programs.“",
    mainLink: "https://paaff.org/",
    facebook: "https://www.facebook.com/PAAFF",
    insta: "https://www.instagram.com/phillyaaff/",
    suggestedFollow: "Check site late summer for annual date",
  },
  {
    title: "Cinema in the Cemetery at Laurel Hill",
    summary: "Runs annually, each year, during summer. Family oriented.",
    mainLink: "https://laurelhillphl.com/events/calendar/",
    facebook: "https://www.facebook.com/Friendsoflaurelhill/",
    insta: "https://www.instagram.com/friendsoflaurelhill/",
    suggestedFollow: "Check site early spring for summer dates",
  },
  {
    title: "Movies on the Block",
    summary: "“Free summer screenings designed to bring groundbreaking films to neighborhoods across Philadelphia.”",
    mainLink: "https://filmadelphia.org/movies-on-the-block/",
    suggestedFollow: "Check site early summer for annual dates",
  },
  {
    title: "Media Film Festival",
    summary: "“Our first festival was in 2008 and we continue to get bigger and better. Join us in celebrating the art of the short film! Generally a five day film fest running across two weekends at end of March + start of April. Second weekend contains a horror short block.“",
    mainLink: "https://www.mediaartscouncil.org/mediafilmfestival",
    facebook: "https://www.facebook.com/MediaFilmFestival/",
    insta: "https://www.instagram.com/mediafilmfestival/",
    suggestedFollow: "Check site early summer for annual date",
  },
];

export const FILMGROUPS = [
  {
    title: "Philm Club",
    summary: "'Philm Club is a project by South Philly Autonomous Cinema that aims to share the events in Philadelphia's film community.' A listing site for Philly indie film screenings and festivals, in bi-monthly email newsletter and site update format. Can submit screens to.",
    mainLink: "https://philmclub.com/",
    insta: "https://www.instagram.com/cinema_philly/",
    suggestedFollow: "Subscribe to newsletter",
  },
  {
    title: "Philadelphia Psychotronic Film Society",
    summary: "“We carry on the proud tradition set forth by Psychotronic Film Societies around the globe by screening cult/weirdo/trash films for the locally like-minded. Every second and last Monday of the month we meet at 7:30 PM.”",
    mainLink: "https://www.philamoca.org/",
    facebook: "https://www.facebook.com/philadelphiapsychotronicfilmsociety",
    suggestedFollow: "FB",
  },
  {
    title: "The Secret Cinema",
    summary: "“Since 1992, the Secret Cinema has been the Philadelphia area’s premiere floating repertory cinema series to nightclubs, bars, coffee houses, museums, open fields, colleges, art galleries, bookstores, and sometimes even theaters and film festivals.” Mostly arthouse, classics, B, political, and retro.",
    mainLink: "http://www.thesecretcinema.com/",
    facebook: "https://www.facebook.com/thesecretcinema/",
    insta: "https://www.instagram.com/therealsecretcinema/",
    suggestedFollow: "Insta + check site monthly for screening date.",
  },
  {
    title: "Space Melt Cinema",
    summary: "“Movies that ooze! Only at Space1026. Ok, sometimes at Cartesian Brewing too.“ Horror, sci-fi, B flicks, etc.." ,
    insta: "https://www.instagram.com/space_melt_cinema/",
    suggestedFollow: "Insta",
  },
  {
    title: "The Bright Bulb Screening Series",
    summary: "“Free Screenings with various curators, the second Thursday of each month @ The Rotunda.” Arthouse, classics, local, B, radical.",
    mainLink: "https://www.therotunda.org/event_category/andrew-s-video-vault",
    facebook: "https://www.facebook.com/The-Bright-Bulb-Screening-Series-112277200239392/",
    insta: "https://www.instagram.com/brightbulbscreenings/",
    suggestedFollow: "Insta or FB",
  },
  {
    title: "Philadelphia Filmmakers Group",
    summary: "General Philly film group.",
    facebook: "https://www.facebook.com/groups/PhillyFilm/",
    suggestedFollow: "FB but do not follow posts on timeline, as too many posts",
  },
];

export const FILMHORROR = [
  {
    title: "Exhumed Films",
    summary: "Runs regular retro horror movies at The Mahoning Drive-In Theater, which is 80 mins N of Center City. Camping at the Mahoning on weekend-long events. Also shows films more locally, at PhilaMOCA, The Colonial, and other venues.",
    mainLink: "https://exhumedfilms.wordpress.com/",
    facebook: "https://www.facebook.com/ExhumedFilms/",
    suggestedFollow: "FB",
  },
  {
    title: "24 Hour Horror-thon",
    summary: "In October at the historic Colonial Theater, run by Exhumed Films. 17 years running. “The biggest horror movie event on the east coast.”",
    facebook: "https://www.facebook.com/ExhumedFilms",
    suggestedFollow: "Tickets sell out FAST. Usually on sale by mid July. Watch their FB in July.",
  },
  {
    title: "The Philadelphia Unnamed Film Festival (PUFF)",
    summary: "“More than just a 'horror' film festival, each year PUFF includes an eclectic mix of features and shorts, including sci-fi, action, documentary, bizarre, drama and more. Shorts films submission selected.” Really good shit. Largely local films. Start of October, annually.",
    mainLink: "https://filmfreeway.com/PhiladelphiaUnnamedFilmFestival",
    facebook: "https://www.facebook.com/phillyunnamed/",
    insta: "https://www.instagram.com/phillyunnamed/",
    suggestedFollow: "Check Insta or FB for annual date in late summer",
  },
  {
    title: "Blobfest",
    summary: "Annual, each July. ”A blobs and monsters themed take on the sci-fi/horror festival. Re-enact the famous Blob run out scene. Experience a live Stage Show and dance the night away to live music. Over 70 vendors. The Blob Run 5k run. A self-guided Driving Tour of Filming Locations featured in The Blob. 25th year in 2024.”",
    mainLink: "https://thecolonialtheatre.com/blobfest/",
    facebook: "https://www.facebook.com/Blobfest",
    insta: "https://www.instagram.com/blobfest/",
    suggestedFollow: "Check for annual date in late spring. Tickets sell out fast.",
  },
  {
    title: "Delco Horror Haven",
    summary: "“We Welcome the Weird! We support the horror community with various pop up events and a film festival.” Media, PA based. Lectures, movie premieres, book clubs and book fairs, indie media resource events, film expos and festivals, and more.",
    mainLink: "https://www.delcohorrorhaven.org/",
    insta: "https://www.instagram.com/delcohorrorhaven/",
    facebook: "https://www.facebook.com/delcohorrorhaven",
    suggestedFollow: "FB or check site calander monthly"
  },
  {
    title: "Blood Sick Productions",
    summary: "Philadelphia underground horror punks producting a consistant stream of cult classics, with films on grindhouse loners, queer romances, and collaborations with local legends Spiter and Devil Master. Lead by director Brewce Longo, “Ed Wood of the 21st century.”",
    mainLink: "https://bloodsickproductions.bigcartel.com/",
    subLink: "https://www.youtube.com/@brewcelongo/videos",
    insta: "https://www.instagram.com/bloodsickproductions/",
    suggestedFollow: "Insta, plus see their films online, at PhilaMOCA, and The Mahoning Drive-in",
  },
  {
    title: "Sick Slice Studios",
    summary: "Creator of 70's/80's style Halloween TV special VHS shot web series Luther Mid's Mid-Fright Snack, which is filmed in front of 'dead studio audiences.' Also recently released their first feature length film, Pizza Party Massacre, which premiered at The Mahoning-Drive in. Has also done music videos for Devil Master and Spiter, album covers, and commissions original artwork.",
    mainLink: "https://www.sickslicestudios.com/",
    subLink: "https://www.youtube.com/@sickslicestudios/videos",
    insta: "https://www.instagram.com/luthersmidfrightsnack/",
    suggestedFollow: "Insta",
  },
  {
    title: "Knucklehead's Films",
    summary: "Queer horror punk films by local Lancaster film maker Jake McClellan. “DIY to the core goofy horror hijinks and high camp queer shenanigans. Check out Knucklehead's first wide release feature length horror film, HeBGB TV online.“",
    mainLink: "https://www.hebgbtv.com/",
    subLink: "https://www.youtube.com/@hebgbtv/videos",
    insta: "https://www.instagram.com/birthdayjake/",
    suggestedFollow: "Insta",
  },
  {
    title: "Lunchmeat VHS",
    summary: "“Your number one destination for the appreciation, celebration and preservation of VHS and video store culture.“ Publish Lunchmeat Magazine, perform screenings, sell groovy merch, hold tape swaps, hold VHS Fest anually, at The Mahoning Drive-In, and run Video Vortex, which hosts 70,000 titles online, free.",
    mainLink: "https://lunchmeatvhs.com/",
    subLink: "https://videovortex.com/raleigh/",
    facebook: "https://www.facebook.com/LunchmeatVHS/",
    insta: "https://www.instagram.com/lunchmeatvhs/",
    twitter: "https://x.com/lunchmeatvhs",
    suggestedFollow: "Insta",
  },
  {
    title: "Philadelphia / suburbs Horror Fans UNITE!",
    summary: "FB group that is good for finding playing horror movies, festivals, etc..",
    mainLink: "https://www.facebook.com/groups/phillyhorror/",
    suggestedFollow: "FB",
  },
  {
    title: "Annual Ghoulastic Book Fair",
    summary: "“Join Delco Horror Haven's 2nd Annual Ghoulastic Book Fair! Discover indie horror authors and Isten to their stories in an historic setting.“ March 22nd, 2025. Media, PA.",
    facebook: "https://www.facebook.com/delcohorrorhaven",
    suggestedFollow: "Check Delco Horror Haven FB late winter for annual date",
  },
  {
    title: "Market of the Macabre",
    summary: "September, annually. “The magical and morbidly curious 2024 Market of the Macabre. Peruse amongst historic tombstones for handmade items, assorted oddities and taxidermy, antiques, jewelry, spiritual goods, clothing, original artwork, and more. Featuring 80+ local and regional artists and vendors.“" ,
    mainLink: "https://laurelhillphl.com/events/annual-events/market-of-the-macabre/",
    facebook: "https://www.facebook.com/laurelhillmarketofthemacabre/",
    insta: "https://www.instagram.com/marketofthemacabre",
    suggestedFollow: "Check for annual date in late summer",
  },
  {
    title: "Baby Goblin Productions",
    summary: "“Baby Goblin Productions is a an independent film production company started by Dorian Vasquez in September, 2022. After filming countless comedy and horror sketches, it was time to do something bigger. We are happy to report that 'Porch Pirate,' our first feature length project has officially wrapped!!“",
    mainLink: "https://babygoblinproductions.com",
    facebook: "https://www.facebook.com/BabyGoblinProductions",
    insta: "https://www.instagram.com/babygoblinproductions",
    suggestedFollow: "Insta",
  },
  {
    title: "Bufallo Bill's House",
    summary: "Bufallo Bill's House, from the original The Silence of the Lambs, largely unchanged in the 30 years since the movie was filmed, open for overnight vacation rental stays and tours. Just south of Pittsburgh.",
    mainLink: "https://buffalobillshouse.com/",
    facebook: "https://www.facebook.com/%40buffalobillshouse/",
    insta: "https://www.instagram.com/buffalobillshouse",
    twitter: "https://x.com/buffbillshouse",
    suggestedFollow: "Visit the house...if you dare",
  },
  {
    title: "See also, 'Theaters' section below, as most of these theaters also show regular horror."
  },
];

export const FILMINDIE = [
  {
    title: "Philadelphia Film Society",
    summary: "Multiple theaters, mostly well regarded not-so-recent films. A lot of classic cinema and arthouse.",
    mainLink: "https://filmadelphia.org/nowshowing/",
    facebook: "https://www.facebook.com/PhillyFilmSoc/",
    insta: "https://www.instagram.com/phillyfilmsociety/ ",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "cinéSPEAK",
    summary: "“The city’s premier art house theater and organization.” Pop-up showings all over Philly, sometimes outdoors, each summer and fall. Focused on global independent cinema on radical topics.",
    mainLink: "https://www.cinespeak.org/attend/",
    subLink: "https://www.instagram.com/cinespeak/",
    facebook: "https://www.facebook.com/cineSPEAKeasy",
    suggestedFollow: "Check site “attend” page monthly",
  },
  {
    title: "The Philadelphia Mausoleum of Contemporary Art (PhilaMOCA)",
    summary: "Mostly concerts, but also arthouse film screenings, with weird b movies, horror, film classics, etc..",
    mainLink: "https://www.philamoca.org/",
    facebook: "https://www.facebook.com/philamoca",
    insta: "https://www.instagram.com/philamoca",
    suggestedFollow: "Insta + check site calendar monthly",
  },
  {
    title: "The Mahoning Drive-In",
    summary: "105 mins N of Philly. Retro, horror, schlock, and b-movies, providing 'extended pass' camping at the back of their lot. Has had John Waters and Lucio Fulci live. Holds the annual VHS Fest (8 years running). The best drive-in theater in North America?",
    mainLink: "https://www.mahoningdit.com/",
    facebook: "https://www.facebook.com/mahoningdriveintheater/",
    insta: "https://www.instagram.com/mahoningdriveintheater/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "Lightbox Film Center",
    summary: "Film classics, foreign, indie, and arthouse. Run a lot of film series, often by director. Greats of cinema (ex. Godard, Kurosawa), as well as arthouse (ex. 'The Draughtsman's Contract' restoration). Now showing at Bok Auditorium.",
    mainLink: "https://lightboxfilmcenter.org/",
    facebook: "https://www.facebook.com/LightboxFilmCenter",
    insta: "https://www.instagram.com/lightboxfilmcenter/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "The Colonial Theatre",
    summary: "55 mins NW of Philly. Arthouse, horror, cult, retro, classics, anime. Blobfest in July and a lot of other horror events.",
    mainLink: "https://thecolonialtheatre.com/",
    facebook: "https://www.facebook.com/thecolonialtheatre",
    insta: "https://www.instagram.com/thecolonialtheatre/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "The Highway",
    summary: "Old theater 35 mins N of Philly. Owned by The Ambler theater. Lots of special showing horror and cult .",
    mainLink: "https://www.hiwaytheater.org/",
    facebook: "https://www.facebook.com/BlackRiverDriveIn",
    insta: "https://www.instagram.com/hiwaytheater",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "The Ambler Theater",
    summary: "45 mins N of Philly. Special shows of arthouse, classics, retro, horror.",
    mainLink: "https://www.amblertheater.org/",
    facebook: "https://www.facebook.com/AmblerTheater/",
    insta: "https://www.instagram.com/amblertheater/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "The County Theater",
    summary: "60 mins N of Philly. Special shows of  arthouse, classics, cult, modern. Non-profit.",
    mainLink: "https://www.countytheater.org/",
    facebook: "https://www.facebook.com/countytheater",
    insta: "https://www.instagram.com/countytheater/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "PhillyCAM",
    summary: "“PhillyCAM operates Philadelphia's Public Access Television Cablecast.” Also does workshops, screenings, etc.",
    mainLink: "https://phillycam.org/watch",
    facebook: "https://www.facebook.com/phillycam",
    insta: "https://www.instagram.com/phillycam/",
    suggestedFollow: "Check site calendar monthly",
  },
];

export const GENERAL = [
  {
    title: "Is Septa Fucked?",
    summary: "Simple SEPTA status site for delays and outages for buses and regional rail.",
    mainLink: "http://www.isseptafucked.com/",
    suggestedFollow: "n/a",
  },
  {
    title: "SEPTA Transit Map",
    summary: "Official SEPTA transit map, in PDF form, making it useful for saving on a smartphone or printing, if not wanting to feed your data to Google or Apple servers.",
    mainLink: "https://www5.septa.org/wp-content/uploads/travel/line-map-rr.pdf",
    suggestedFollow: "n/a",
  },
  {
    title: "Grays Ferry Skatepark",
    summary: "Skatepark under Greys Ferry Bridge by Schuykill River, just before West Philly. Common gathering spot for punks and anarchists. Generator shows. Generator movies. Swap markets. Skateboarding.",
    facebook: "https://www.facebook.com/pages/Grays-Ferry-Skatepark/500800656683053",
  },
  {
    title: "9th and Poplar Skatepark",
    summary: "North Philly DIY skate park under a bridge. Cool spot. Really long park, full of DIY ramps, rails, quarter pipes, smaller bowls, etc. Covered in graff. Opened 2008. ",
    insta: "https://www.instagram.com/9thandpoplar",
  },
  {
    title: "FDR Skatepark",
    summary: "Located beneath the overpass of I-95, this world-renowned skatepark was created by skaters, for skaters. The park has several regions, including the core park that lies under the aforementioned overpass, the neighboring and connecting pool section, a mini ramp, and a vert ramp. Adorned with an ever-changing collection of tags and art. Also, occassional wrastlin', shows, and other events.",
    mainLink: "https://www.fdrparkphilly.org/skatepark",
    facebook: "https://www.facebook.com/groups/FDRSKATEPARK/",
    insta: "https://www.instagram.com/fdrskatepark",
  },
  {
    title: "Space 1026",
    summary: "“Space 1026 has been a 18 year experiment and is two floors of a building at 11th and Arch. It is a network of dozens of artists who‘ve had studios at the Space, past and present. Space 1026 is a community – a creative community – not an institution.“",
    mainLink: "https://space1026.com/",
    facebook: "https://www.instagram.com/space1026/",
    insta: "https://www.facebook.com/space1026/",
    suggestedFollow: "Check their site calender monthly",
  },
  {
    title: "Cherry Blossom Festival",
    summary: "April, annually. Horticulture Center in West Fairmount Park. A spring celebration of Japanese arts and culture organized by Japan America Society of Greater Philadelphia. Taiko Drum performances under the cherry blossom, and so forth.",
    mainLink: "https://japanphilly.org/programs/festivals/cherryblossom/",
    facebook: "https://www.facebook.com/Shofuso",
    insta: "https://www.instagram.com/phillysakura/",
    suggestedFollow: "Check annual date in early spring",
  },
  {
    title: "Philly Social Adventures",
    summary: "A group run by the same guy who runs the Glory Beer Bar and Ladder 15 Game Nights. General nerd gatherings. A lot of regulars.",
    facebook: "https://www.facebook.com/groups/574330306077914/",
    suggestedFollow: "FB",
  },
  {
    title: "Italian Market Festival",
    summary: "Late spring, annually. “For seven city blocks, a celebration of culture, gastronomy, art and music. Featuring over 100 area vendors showcasing a range of cuisine and beverages. In addition to the food, the Festival features a wide range of music, services, souvenirs, jewelry, artists, and crafters.“ Greased pole climbing competition.",
    mainLink: "https://italianmarketphilly.org",
    facebook: "https://www.facebook.com/italianmarketphilly/",
    insta: "https://www.instagram.com/italianmarketphilly/",
    suggestedFollow: "Check early spring for annual date",
  },
  {
    title: "Old City First Friday",
    summary: "“On the First Friday of the every month, Old City comes alive in celebration as the galleries open new exhibits, design showrooms display their new lines, and businesses stay open late with special programs.“ Your typical First Friday; tour galleries, look at art, enjoy some free wine and cheese.",
    mainLink: "https://www.oldcitydistrict.org/first-friday",
    facebook: "https://www.facebook.com/OldCityDistrict/",
    insta: "https://www.instagram.com/phillyfirstfriday/",
    suggestedFollow: "Check it out some Friday",
  },
  {
    title: "praise dobler",
    summary: "The most recognizable graff arist in Philadelphia, praise dobler, in recognition of Lord Conrad Dobler and the Transcendent True-Faith Church of dobler.",
    mainLink: "https://praisedobler.bigcartel.com/",
    subLink: "https://streetsdept.com/2023/09/26/philly-street-art-interviews-praise-dobler-means-more-than-you-think-to-more-people-than-youd-expect/",
    subSubLink: "https://southstreetartmart.com/collections/praise-dobler",
    insta: "https://www.instagram.com/praisedobler/",
    suggestedFollow: "Look for him ",
  },
  {
    title: "Annual Classic Hearse Show",
    summary: "Late summer, annually. “If you’re dying for a truly unique experience, the Classic Hearse and Ambulance Show at Laurel Hill East has you covered! Check out coffin cruisers from across the decades and talk shop with fellow car buffs.“",
    mainLink: "https://laurelhillphl.com/event/hearse-show/",
    facebook: "https://www.facebook.com/mohntonprocars",
    insta: "https://www.instagram.com/mohntonprofessionalcarclub/",
    suggestedFollow: "Check FB or Insta mid summer for annual date",
  },
  {
    title: "Hidden City Philadelphia",
    summary: "“Hidden City Philadelphia of CultureTrust is dedicated to exploring Philadelphia’s urban landscape in all its complexity through journalism and public history.“ A nifty page to explore and learn about hidden and historic Philly, like Devil's Pool, Pier 68, and The Dead Fleet.",
    mainLink: "https://hiddencityphila.org/",
    facebook: "https://www.facebook.com/hiddencityphila/",
    insta: "https://www.instagram.com/hiddencityphila/",
    twitter: "https://twitter.com/HiddenCityPhila",
    suggestedFollow: "Check their site for cool articles",
  },
  {
    title: "GrimGrimGrim",
    summary: "Another recognizable Philly artist, GrimGrimGrim has made many posters for local hardcore, metal, etc. bands, and also creates t-shirts, prints, bumper stickers, hats, and more. Art often features grimkvlt versions of Philly icons, like Gritty, Phillie Phanatic, WaWa, and Cheesteak.",
    mainLink: "https://www.grimgrimgrim.com",
    facebook: "https://www.facebook.com/GRIM6GRIM6GRIM6",
    insta: "https://www.instagram.com/grimgrimgrim215",
    twitter: "",
    suggestedFollow: "FB or Insta + check out his shop",
  },
];

export const GREENGARDENS = [
  {
    title: "The People’s Kitchen",
    summary: "“A collaborative of chefs, students, and community members growing and cooking free, delicious meals in Philly.” Kitchen for various rad groups. Free hot meals, outside the kitchen, every day, 3:00pm.",
    mainLink: "https://www.peopleskitchenphilly.com/",
    facebook: "https://www.facebook.com/profile.php?id=100090422833314",
    insta: "https://www.instagram.com/peopleskitchenphilly",
    suggestedFollow: "Instagram, with main site to sign up for cook shift, food delivery, etc.",
  },
  {
    title: "Fair-Amount Food Forest",
    summary: "“Fair-Amount Forest is an effort to establish an open-harvest, community driven, medium-scale food forest on public land in Philadelphia, PA.” Community gardening nights, monthly work parties, etc." ,
    mainLink: "https://www.fairamountfoodforest.org/calendar.html",
    subLink: "https://www.fairamountfoodforest.org/resources.html",
    facebook: "https://www.facebook.com/FairAmountFoodForest/",
    insta: "https://www.instagram.com/fairamountfoodforest",
    suggestedFollow: "Insta + Check site calendar monthly",
  },
  {
    title: "Philadelphia Orchard Project",
    summary: "“Works with community-based groups and volunteers to plan and plant orchards filled with useful and edible plants in formerly vacant lots, community gardens, schoolyards, and other spaces, primarily in low-wealth neighborhoods where people have limited access to fresh fruit.”",
    mainLink: "https://www.phillyorchards.org",
    facebook: "https://www.facebook.com/PhillyOrchards",
    insta: "https://www.instagram.com/phillyorchards/",
    suggestedFollow: "Email newsletter (signup on site) + Insta + check site 'events' page monthly.",
  },
  {
    title: "Mill Creek Farm",
    summary: "“Founded in 2005, Mill Creek Farm is an educational urban farm that is led by people of color.” Large enough to run a bi-weekly farmers' market. Volunteer opportunities.",
    mainLink: "https://www.millcreekurbanfarm.org/",
    facebook: "https://www.facebook.com/mcufphilly",
    insta: "https://www.instagram.com/mcufphilly/",
    suggestedFollow: "Insta. Check site for farmers’ market locations and volunteer opps.",
  },
  {
    title: "Philadelphia Horticultural Society Community Garden Map",
    summary: "“Check out our map and learn more about the breadth of community gardens and urban farms in our region.“",
    mainLink: "https://phsonline.org/programs/community-gardens/find-your-community-garden",
    suggestedFollow: "n/a",
  },
  {
    title: "Community Compost Network Free Compost Bins Map",
    summary: "“The Community Compost Network supports composting efforts at 13 sites across the city. The sites include community gardens, farms, and schools. At these sites, residents can compost their food and yard waste.“",
    mainLink: "https://www.phila.gov/programs/farmphilly/community-composting/#community-compost-network",
    suggestedFollow: "n/a",
  },
  {
    title: "The Fair-Amount Park Organic Recycling Center",
    summary: "Get up to 30 gallons of free Compost from the city, including screened leaf compost, mulch, double-ground mulch, wood chips, and other organic materials.",
    mainLink: "https://www.phila.gov/services/trees-parks-the-environment/get-organic-materials/ ",
    suggestedFollow: "n/a",
  },
  {
    title: "Neighborhood Gardens Trust",
    summary: "Land trust that holds deeds for community gardens in Philadelphia and provides support to community gardens, including legal support, securing tax exemptions, setting up water service, etc..",
    mainLink: "https://www.ngtrust.org/",
    facebook: "https://www.facebook.com/ngtrust",
    insta: "https://www.instagram.com/ngtrust/",
    suggestedFollow: "Facebook (more event posts)",
  },
  {
    title: "FarmerJawn",
    summary: "“A 128-acre working farm, building a model of regenerative organic food production by and for underserved communities. The largest Black-woman owned regenerative organic produce farm in America. Education is offered through on-farm and in-class school-based programming and adult agri-business training.“",
    mainLink: "https://www.farmerjawn.co/",
    facebook: "https://www.facebook.com/FarmerJawnPhilly",
    insta: "https://www.instagram.com/farmerjawn_",
    suggestedFollow: "Insta",
  },
];

export const GREENMARKETS = [
  {
    title: "Philadelphia + Surrounding Area Farmers’ Market List",
    summary: "A list of 60+ Philadelphia + surrounding area farmers' markets. From 2021, so may be a bit out of date.",
    mainLink: "https://www.thegreencities.com/food/2021-farmers-market-guide/",
    suggestedFollow: "n/a",
  },
  {
    title: "Philadelphia + Surrounding Area CSA List",
    summary: "A list of 20 Philadelphia + surrounding area CSA",
    mainLink: "https://www.phillymag.com/be-well-philly/csa-farm-share/",
    suggestedFollow: "n/a",
  },
  {
    title: "Mariposa Co-op",
    summary: "“A member-owned and operated retail food cooperative operating out of a storefront in West Philadelphia since the early 1970s.”",
    mainLink: "https://mariposa.coop/",
    facebook: "https://www.facebook.com/MariposaCoop/",
    insta: "https://www.instagram.com/mariposacoop/",
  },
  {
    title: "Weavers Way Food Coop",
    summary: "Two grocery locations, one in Mt. Airy and one in Chestnut Hill, w/ a vintage & 'local goods' shop also in Mt. Airy and 'health, wellness, and beauty products' stores in Mt. Airy and Chestnut, also. A final grocery in nearby Amlber, PA.",
    mainLink: "https://weaversway.coop/ ",
    facebook: "https://www.facebook.com/weaversway",
    insta: "https://www.instagram.com/weaversway/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "South Philly Food Coop",
    summary: "“South Philly Food Co-op is South Philly’s only community-owned grocery store. It offers fresh, local foods at fair prices and serves as a hub for our South Philly community.“",
    mainLink: "https://www.southphillyfood.coop/",
    facebook: "https://www.facebook.com/SouthPhillyFoodCoop",
    insta: "https://www.instagram.com/spfoodcoop/",
  },
  {
    title: "Mycopolitan Mushroom CSA",
    summary: "A philly local mushroom CSA with bi-weekly pickups of 2.3+ lbs of mushrooms of various varieties.",
    mainLink: "https://www.mycopolitan.com",
    facebook: "https://www.facebook.com/mycopolitan",
    insta: "https://www.instagram.com/mycopolitan/",
  },
  {
    title: "Sprouts Farmers Market",
    summary: "National health foods store chain with a lot of produce. Especially good for its tons of bulk food. Northeast Philly, Penn's Landing, and South Philly.",
    mainLink: "https://www.sprouts.com/",
    facebook: "https://www.facebook.com/SproutsFarmersMarket",
    insta: "https://www.instagram.com/sprouts/",
  },
  {
    title: "Reading Terminal Market",
    summary: "A big open air market in Center City. Multiple good, cheap produce shops here.",
    mainLink: "https://readingterminalmarket.org/",
    facebook: "https://www.facebook.com/readingterminalmarket",
    insta: "https://www.instagram.com/rdgterminalmkt/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "Italian Market",
    summary: "Another big open air market with many street vendors running down 9th street in South Philly. Began late 1880’s. Tons of cheap produce. Live fowl. Gnocchi. Mexican restaurants. Old school.",
    mainLink: "https://www.italianmarketphilly.org/",
    facebook: "https://www.facebook.com/italianmarketphilly/",
    insta: "https://www.instagram.com/italianmarketphilly/",
    suggestedFollow: "None, but check for events around holidays",
  },
  {
    title: "Edible Philly",
    summary: "News/blog site on food farms, farmers' markets, local food restaurants.",
    mainLink: "https://ediblephilly.ediblecommunities.com/",
    facebook: "https://www.facebook.com/EdiblePhilly",
    insta: "https://www.instagram.com/ediblephilly",
    suggestedFollow: "None, but check if looking for vegan/organic restaurants.",
  },
];

export const GREENFORAGINGGENERAL = [
  {
    title: "Pennsylvania Wild Mushroom Gathering Group",
    summary: "Foraging events, mushroom ID posts, plant walks, meet and greets, general group.",
    facebook: "https://www.facebook.com/groups/317282268813938/",
    suggestedFollow: "Facebook, but mute/limit on timeline, due to many posts",
  },
  {
    title: "Philadelphia Mycology Club",
    summary: "Mushroom hunts. Wild food foraging walks. Cobb’s Creek monthly cleanups. Speakers. Camping trips.",
    mainLink: "https://www.facebook.com/phillymycoclub/",
    facebook: "https://www.facebook.com/groups/phillymycoclub/",
    suggestedFollow: "Facebook, but mute/limit the “Friends of…” group on timeline, due to many posts",
  },
  {
    title: "NJ Mycological Association",
    summary: "Very frequent mushroom hunts, weekly, in warm seasons. Special events (ex. “Fluorescent Fungus Night“). Speakers. Films. Annual potluck. Used book sales at foraging.",
    mainLink: "https://www.njmyco.org/forays.html",
    facebook: "https://www.facebook.com/groups/NewJerseyMycologicalAssociation/",
    insta: "https://www.instagram.com/nj.myco/",
    suggestedFollow: "Insta (for official posts) and Facebook, for discussion, but mute/limit FB timeline, due to many group user posts",
  },
  {
    title: "Wild Foodies of Philly",
    summary: "General foraging hikes and walks, often foraging for specific foods (ex. berries) . Multiple hikes and walks per week, in warm seasons. Some surrounding area hikes.",
    mainLink: "https://www.meetup.com/Wild-Foodies-of-Philly/",
    suggestedFollow: "meetup",
  },
  {
    title: "The Northeast Mycological Federation Samuel Ristich Foray",
    summary: "47th annual, in 2024. “The Northeast Mycological Federation (NEMF) coordinates between 24+ mycology clubs from Pennsylvania to Quebec. Each year a rotating group of clubs co-hosts NEMF's main activity, the Sam Ristich Foray. This is three days of fungus collection and identification, lectures and workshops, mycophagy, medicinal uses, textile dyeing, papermaking & photography.“",
    mainLink: "https://www.nemf.org/",
    facebook: "https://www.facebook.com/groups/1502966589998892/",
    suggestedFollow: "",
  },
];

export const GREENGENERAL = [
  {
    title: "Grid Philly Events",
    summary: "Very extensive general calendar of Philly nature, green, etc. events. A really good event resource. Local enviro news articles also posted once a month.",
    mainLink: "https://gridphilly.com/events ",
    suggestedFollow: "Check site events calendar + articles monthly",
  },
  {
    title: "North Philly Peace Park",
    summary: "“An open, fence-free, charitable, intergenerational, neighborhood-managed ecology campus championing food, education and community.” Example events: Black August Garden Party, Black Land Futures Weekend, Community Health and Wellness Fair.",
    mainLink: "https://www.phillypeacepark.org/",
    facebook: "https://www.facebook.com/NorthPhillyPeacePark",
    insta: "https://www.instagram.com/northphillypeacepark/",
    suggestedFollow: "Facebook (more updated)",
  },
  {
    title: "Everyday Futures Fest",
    summary: "“An annual, free, arts, science, and culture festival, towards Self-Sufficiency And Collective Resilience.” Programs and events running all through April each year. Workshops, exhibitions, panels, family day, letter writing, presentations, etc. (more workshops than anything else). Held at locations all around Philly.",
    mainLink: "https://everydayfuturesfest.org/",
    facebook: "https://www.facebook.com/davinciartalliance",
    insta: "https://www.instagram.com/davinciartalliance",
    suggestedFollow: "Check site early spring for annual event details",
  },
  {
    title: "Rutabaga Toy Library",
    summary: "“At Rutabaga, we believe that sharing instead of buying new, is not only good for the environment, it also helps us build strong communities. We're your resource for sustainable parenting: Toy Lending Library Memberships // Zero-Waste Birthday Parties // Playdates and Gathering Space // Nature Arts Afterschool & Camp Program. Established in 2019.“",
    mainLink: "https://www.rutabagatoylibrary.com/",
    facebook: "https://www.facebook.com/RutabagaPhilly",
    insta: "https://www.instagram.com/rutabagaphilly/",
    suggestedFollow: "Facebook, for events and announcements",
  },
  {
    title: "Permaculture Philadelphia",
    summary: "General group for Philly permaculture events, resources, etc.",
    mainLink: "https://www.facebook.com/groups/permaculturephiladelphia/",
  },
  {
    title:"Parade of Spirits",
    summary: "An annual parade inspired by inspired by Krampuslauf, an honoring of Krampus. Full of complex and intricate costumes, masks, and witchy and earthy attire. A pagan celebration just around the winter solstice. 15th year in 2025. “Like being in a Miyazaki movie.“",
    mainLink: "https://whyy.org/articles/krampus-brings-dark-winter-magic-to-northern-liberties/",
    facebook: "https://www.facebook.com/paradeofspiritsll/",
  },
];

export const GREENHIKINGPARKS = [
  {
    title: "Hiking Around Philly",
    summary: "Public hike group. Frequent Hikes. Multiple per week, often 1.5 to 4 hours long. Longer hikes every other weekend or so. Has seasonal foraging hikes.",
    mainLink: "https://www.meetup.com/HikingAroundPhilly/",
    suggestedFollow: "meetup",
  },
  {
    title: "Batona Hiking Club",
    summary: "Public hike group. Very old group with a long history. A couple hikes a month, sometimes much more rural, further out of Philadelphia, into the mountains.",
    mainLink: "https://www.meetup.com/batona-hiking-club/",
    suggestedFollow: "meetup",
  },
  {
    title: "Alltrails: Top Hikes Near Philadelphia",
    summary: "Best way to find and filter hiking trails by distance, elevation change, etc.. Social app. People rank trails, take photos, leave notes (ex. “Careful, area is extremely buggy in August.”).",
    mainLink: "https://www.alltrails.com/us/pennsylvania/philadelphia",
    suggestedFollow: "n/a",
  },
  {
    title: "My Philly Park: Parks Map",
    summary: "An interactive map of all the public parks in Philadelphia.",
    mainLink: "https://myphillypark.org/explore/map/",
    suggestedFollow: "n/a",
  },
  {
    title: "Philly Day Hiker",
    summary: "A nice collection of parks and trails suitable for day hikes on an interactive map of Philly and the surrounding area.",
    mainLink: "https://phillydayhiker.com/",
    suggestedFollow: "n/a",
  },
  {
    title: "Wissahickon Valley Park Trails",
    summary: "A nice list of all major trails in Wissahickon and directions to get to them.",
    mainLink: "https://fow.org/visit-the-park/maps/#trail-descriptions",
    suggestedFollow: "n/a",
  },
  {
    title: "Philly Optimized Walking Tour Map",
    summary: "A 12 mile walking tour of Philly passing through 25 notable spots and attractions.",
    mainLink: "https://randalolson.com/2015/03/25/optimized-walking-tours-of-new-york-city-and-philadelphia",
    ssuggestedFollow: "n/a",
  },
  {
    title: "Fairmount Park Trails",
    summary: "“Your guide to discovering the unknown in Philadelphia's largest park.” Did you know Fairmount Park is 10x the size of Central Park? Full of old growth, too.",
    mainLink: "https://storymaps.arcgis.com/stories/4c160765a50541a4b77b45957ce77ea9",
    suggestedFollow: "n/a",
  },
  {
    title: "Philly Trails",
    summary: "Someone’s covid project page mapping out and providing trail descriptions of all trails in Philly. Also has a smaller number of trails for down the shore.",
    mainLink: "https://www.phillytrails.com/",
    suggestedFollow: "n/a",
  },
  {
    title: "Visit Philly: Best Parks in Philadelphia",
    summary: "“The 26 Best Parks in Philadelphia. Explore Philly’s incredible outdoor spaces.” Includes urban as well as forested parks.",
    mainLink: "https://www.visitphilly.com/articles/philadelphia/best-parks-in-philadelphia/",
    suggestedFollow: "n/a",
  },
  {
    title: "Fairmount Park Conservancy",
    summary: "“Brings parks to life. We work with the City of Philadelphia and its communities to steward our parks and nurture our shared environment, cultural resources and public health.” Kayaking, scavenger hunts, hikes, bird walks, foraging, meditation, food markets. Volunteer park cleanup and stewardship events often.",
    mainLink: "https://myphillypark.org/",
    facebook: "https://www.facebook.com/myphillypark",
    insta: "https://www.instagram.com/myphillypark/",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "The Schuykill Center for Environmental Education",
    summary: "“Founded in 1965, the Schuylkill Center is one of the first urban environmental education centers in the country, with 340 acres of fields, forests, ponds, and streams in NW Phil.” Hikes, foraging, art, conservation projects, wildlife clinic, programs. Many volunteer options.",
    mainLink: "https://www.schuylkillcenter.org/ ",
    facebook: "https://www.facebook.com/schuylkillcenter",
    insta: "https://www.instagram.com/schuylkillcenter",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title: "Green Philly: Delaware Watershed Resource Directory",
    summary: "“Explore your Delaware Watershed.” An interactive map of non-profits, education centers, trails, parks, museums, etc., related to and located in the Delaware Watershed.",
    mainLink: "https://www.thegreencities.com/watershed-directory/",
    suggestedFollow: "n/a",
  },
  {
    title: "Devil's Pool",
    summary: "Devil’s Pool has been a popular spot for sunbathing and swimming in the Wissahickon for centuries. In the past few decades the site has become the focus of much handwringing, endless op-eds, neighborhood organizing, & trash pick-ups. Devil’s Pool is often described as suffering from overuse, so tred with care.",
    mainLink: "https://hiddencityphila.org/2022/06/photographer-captures-heaven-on-earth-at-devils-pool/",
  },
];

export const LITERATURE = [
  {
    title: "Philadelphia Zine Fest",
    summary: "Typically a spring fest and a winter fest. Over 150 exhibitors, after moving to a much larger venue. Check on insta once a month to make sure you catch them. Check their insta for other local zine fests, also.",
    mainLink: "https://www.phillyzinefest.org/",
    facebook: "https://www.facebook.com/phillyzinefest",
    insta: "https://www.instagram.com/phillyzinefest/",
    suggestedFollow: "Insta + check site early fall for annual date",
  },
  {
    title: "Book Corner",
    summary: "Philly library used bookstore. Cheap and w/ good selection. Hold book sales a few times a year, with fall sales being the most common season.",
    facebook: "https://www.facebook.com/bookcornerphilly",
    insta: "https://www.instagram.com/bookcornerphilly ",
    suggestedFollow: "None, but check their FB monthly for book sale dates",
  },
  {
    title: "Bucks County Book Festival",
    summary: "Doylestown, PA. Books, author events, small presses. End of Sept annually.",
    mainLink: "https://www.bucksbookfest.org/",
    suggestedFollow: "Check site later summer for annual date",
  },
  {
    title: "Collingswood Book Festival",
    summary: "Collingswood, NJ. Books, author events, small presses. Start of Oct annually.",
    mainLink: "https://www.collingswoodbookfestival.com/",
    suggestedFollow: "Check site early fall for annual date",
  },
  {
    title: "HippoCamp: Conference for Creative Nonfiction Writers",
    summary: "Lancaster, PA. Late summer. A “three-day creative writing conference in PA. Typically features 40+ notable speakers, engaging sessions in four tracks, interactive panels, readings, social activities, networking opps, and optional, intimate pre-conference workshops. All of this, plus meals and snacks.”",
    mainLink: "https://hippocampusmagazine.com/conference/",
    suggestedFollow: "Check site early summer for annual date",
  },
  {
    title: "The Mad Poets Society",
    summary: "Monthly reading series, poetry bonfires, panels, Mad Poets Festival (October annually), Philly Poetry Festival, workshops, competitions, etc.",
    mainLink: "http://www.madpoetssociety.com",
    facebook: "https://www.facebook.com/TheMadPoetsSociety",
    insta: "https://www.instagram.com/madpoetssocietypa/",
    suggestedFollow: "Insta + check site calendar monthly",
  },
  {
    title: "Lancaster Zine Fest",
    summary: "Mid spring, annually. “Zines, art, music, & workshops at the lovely southern market. 40+ tablers, workshops & djs spinning after party tunes!“",
    mainLink: "https://lancasterzinefest.my.canva.site/",
    insta: "https://www.instagram.com/lancasterzinefest/",
    suggestedFollow: "Insta + check for annual date in early spring",
  },
  {
    title: "West Philly Spring Zine Fest",
    summary: "Mid spring and mid winter, annually. “New Zine Show Incoming! Organized by Philly Soapbox, over 50 vendors, admission is FREE! Come out and Support the Philly Zine Community!“",
    mainLink: "https://www.phillysoapbox.org/",
    facebook: "https://www.facebook.com/phillysoapbox/",
    insta: "https://www.instagram.com/phillyzinefest/",
    suggestedFollow: "Philly Zine Fest Insta, for postings on all upcoming zine fests",
  },
  {
    title: "Brown Brainy Brilliant Book Festival & Market",
    summary: "“An annual (mid spring) free literary event showcasing a diverse group of independently published authors from around the country—particularly those of color and from the LGBTQ community. Food, vendors, authors, activities, and more!“ 4th annual as of 2024.",
    mainLink: "https://www.bartramsgarden.org/?s=brown+brainy",
    suggestedFollow: "Check Bartram's Garden Site Calendar mid spring for annual date",
  },
  {
    title: "Philly Queer Zine Fest",
    summary: "June 24, 2023. Occurred first in 2018 and now again in 2023. 2024 incoming?",
    mainLink: "https://www.queerzinefest.com/",
    insta: "https://www.instagram.com/phillyzinefest/",
    suggestedFollow: "No FB or Insta but Philly Zine Fest Insta is good for many zine fests",
  },
  {
    title: "printPHILLY!",
    summary: "Run by the Philadelphia Print Consortium, the fair features local individual printmakers, community and private print studios, educational programs, art institutions, museums, and galleries. Second year in 2024. Typically occurs mid spring.",
    mainLink: "https://printphilly.org/fair/",
    insta: "https://www.instagram.com/philly.print.consortium/",
    suggestedFollow: "Check fair site in early spring for annual date",
  },
  {
    title: "The Soapbox",
    summary: "“The Soapbox is a nonprofit community print shop, book and zine making center, and library of 3,500 rare handmade publications.“ Throws zine fests, holds workshops (ex. screen printing 101), etc..",
    mainLink: "https://www.phillysoapbox.org/",
    facebook: "https://www.facebook.com/phillysoapbox",
    insta: "https://www.instagram.com/phillysoapbox/",
    suggestedFollow: "Site calendar",
  },
];

export const MUSICABOVEPHILLY = [
  {
    title: "Cousin Danny’s Lounge",
    summary: "“West Philly DIY.” A bar, but more DIY than bar. A two flat converted into a space. Lots of metal, hardcore, etc..",
    insta: "https://www.instagram.com/cousindannyslounge/",
  },
  {
    title: "Century",
    summary: "The main above ground spot for metal shows along with Cousin Danny's. Also, noise, post-punk, punk, hardcore, emo, shoegaze, etc.",
    facebook: "https://www.facebook.com/centuryphila/",
    insta: "https://www.instagram.com/centuryphila/",
  },
  
  {
    title: "Ortlieb's",
    summary: "North Philly dive bar and restaurant. Local bands, DJs, karaoke Tuesdays, dance parties.",
    mainLink: "https://ortliebsphilly.com/",
    facebook: "https://www.facebook.com/OrtliebsPhilly/",
    insta: "https://www.instagram.com/ortliebsphilly/",
  },
  {
    title: "Tattooed Mom",
    summary: "Sticker and graff slathered walls punk rock bar on South Street with affordable vegan bar food and a mix of drinks. Pool tables. Lots of events; craft days, VHS Swaps, queer open mic, live readings, comedy shows, art marts, bingo, mixers, indie movie screenings, etc..",
    mainLink: "https://www.tattooedmomphilly.com/",
    insta: "https://www.instagram.com/tmoms/",
    facebook: "https://www.facebook.com/tattooedmomphilly/",
  },
  {
    title: "Underground Arts",
    summary: "“From experimental theater to drag fashion shows, and plenty of concerts. A home for visual artists, UA houses installations by Philly locals, among many others.” Weds night open mics. Monthly Horror Movie Mondays. Punk, hardcore, indie, metal, emo, dance parties, hip hop, stand up, art shows.",
    mainLink: "https://undergroundarts.org/",
    facebook: "https://www.facebook.com/UndergroundArtsPhilly/",
    insta: "https://www.instagram.com/undrgroundarts/",
  },
  {
    title: "Bonk's Bar",
    summary: "Port Richmond. Bar & crabhouse. Local bands, karaoke, car shows, bingo, Quizzo, Christmas toy drive. A solid place for reasonably priced seafood, too.",
    mainLink: "https://bonksbar.com/",
    insta: "https://www.instagram.com/bonksbarphl/",
    facebook: "https://www.facebook.com/bonkscrabs",
  },
  {
    title: "Nikki Lopez",
    summary: "“Cocktail dive bar, hot dog nightclub.“ New Bar in South Philly where Dobb's used to be. Coming into Philly with a bang with a epic spring 2025 concert line up, bands/musicians including Days and Dayz (3 nights), Dead Boys, Green Jelly, Avengers, Joe Jack Talcum (Dead Milkmen), and other legends. Mixes it up with Cyberpunk Dance Parties, House music nights, Yarga Art Mart, and hot dog eating contests, and more. Cheer beers. Hot dogs.",
    insta: "https://www.instagram.com/nikkilopezphilly",
    facebook: "https://www.facebook.com/people/Nikki-Lopez-Philadelphia/61569813447077",
  },
  {
    title: "FU Church",
    summary: "First Unitarian Church of Philadelphia. Semi-frequent punk, hardcore, other, shows. Also home of the Philadelphia Animation Society. Located in a busy spot in Center City so ride a bike to avoid paying for expensive parking. Watch local promoters for shows.",
    subLink: "https://www.songkick.com/venues/10848-first-unitarian-church",
  },
  {
    title: "Ukie Club",
    summary: "Ukrainian American Citizens' Association. Has a bar and hosts local shows, mostly hardcore, though also indie/art rock. Watch local promoters for shows.",
    insta: "https://www.instagram.com/ukrainianleagueofphiladelphia",
  },
  {
    title: "Kung Fu Necktie",
    summary: "Small 140 capacity music venue. Garage rock, punk, folk punk, ska, noise rock, goth, hardcore, crossover, emo, synth-pop. Big name and local bands.",
    mainLink: "https://kungfunecktie.com/",
    facebook: "https://www.facebook.com/kungfunecktie/",
    insta: "https://www.instagram.com/kungfunecktie_bar/",
  },
  {
    title: "MilkBoy",
    summary: "Bar and restaurant with an area for shows in the back. Local and non-local bands. South street location also does open mic, video game nights, etc..",
    mainLink: "https://milkboyphilly.com/",
    facebook: "https://www.facebook.com/MilkBoyPhilly/",
    insta: "https://www.instagram.com/milkboyphilly/",
  },
  {
    title: "Silk City",
    summary: "Super old school diner and bar with big area outdoors. Shows, a couple times a week, sometimes metal.",
    mainLink: "https://www.silkcityphilly.com/",
    facebook: "https://www.facebook.com/silkcityphilly/",
    insta: "https://www.instagram.com/silkcitydiner",
  },
  {
    title:"Warehouse on Watts",
    summary: "Warehouse turned nightclub. “A series of club nights spanning genres, subcultures, and overall vibe.“",
    mainLink: "https://wowphilly.com/",
    facebook: "https://www.facebook.com/wowphilly/",
    insta: "https://www.instagram.com/wowphilly/",
  },
  {
    title: "The Fire",
    summary: "Female-owned “grass roots rock club” dive bar and show venue. Mostly hardcore & punk. Some metal, goth, indie. ",
    mainLink: "https://www.firephilly.com/",
    subLink: "",
    facebook: "https://www.facebook.com/thefirephilly/",
    insta: "https://www.instagram.com/firephilly/",
  },
  {
    title: "The Rotunda",
    summary: "“We are a live performing arts and a multi-cultural sober space designed to fit the needs of Philly curators and creatives.” Shows, but also poetry, dance events, film, speakers, workshops, etc.",
    mainLink: "https://therotunda.org/ ",
    facebook: "https://www.facebook.com/therotundaphilly ",
    insta: "https://www.instagram.com/the_rotunda_philly/",
  },
  {
    title: "Vox Populi",
    summary: "“Founded in 1988, Vox Populi is a contemporary art space and artist collective. Rotating membership. Commitment to working collectively. Monthly exhibitions, gallery talks, performances, lectures, etc.“",
    mainLink: "https://voxpopuligallery.squarespace.com/events",
    facebook: "https://www.facebook.com/voxpopulisphere/",
    insta: "https://www.instagram.com/voxpopuligallery/",
  },
  {
    title: "Johnny Brenda's",
    summary: "“Your favorite Fishtown spot for over 15 years. Local Beer. Good Eats. Craft Cocktails. Live music.“ Bar and restaurant. Live music of many genres on most Th-Sun, as well as some weekdays.",
    mainLink: "https://johnnybrendas.com/",
    facebook: "https://www.facebook.com/Johnny.Brendas",
    insta: "https://www.instagram.com/johnnybrendas/",
  },
  {
    title: "Dawson Street Pub",
    summary: "Manayunk. Local bands.",
    mainLink: "http://www.dawsonstreetpub.com/",
    facebook: "https://www.facebook.com/DawsonStreetPub",
    insta: "https://www.instagram.com/dawsonstreetpub/",
  },
  {
    title: "Dolphin Tavern",
    summary: "“A cross between a dive bar and a nightclub, The Dolphin is South Philly’s most famous nightlife destination.“ Hosts local bands in addition to dance events.",
    mainLink: "http://www.dolphinphilly.com/",
    facebook: "https://www.facebook.com/DolphinPhilly/",
    insta: "https://www.instagram.com/dolphinphilly",
  },
  {
    title: "Franklin Music Hall",
    summary: "Large 2,750 capacity venue that does infrequent shows.",
    mainLink: "https://www.bowerypresents.com/greater-philly/shows/franklin-music-hall",
    facebook: "https://www.facebook.com/franklinmusichall/",
    insta: "https://www.instagram.com/franklinmusichall",
  },
  {
    title: "The Foto Club",
    summary: "“A members only social club,” open Weds thru Sun 7pm-3am. Large open bar area, with food truck and fire pit in back, bar inside. Some public shows, mostly punk and hardcore. Some metal, emo.",
    mainLink: "https://www.thefotoclub.com/",
    facebook: "https://www.facebook.com/thefotoclubofficial/",
    insta: "https://www.instagram.com/fotoclubofficial/",
  },
  {
    title: "Union Transfer",
    summary: "Larger small size venue. All types of music.",
    mainLink: "https://utphilly.com/",
    facebook: "https://www.facebook.com/UnionTransfer/",
    insta: "https://www.instagram.com/uniontransfer/",
  },
  {
    title: "Ray’s Happy Birthday Bar",
    summary: "“80-year-old 'everybody knows your name' neighborhood watering hole.” Iconic spot.",
    mainLink: "https://www.thehappybirthdaybar.com/",
    insta: "https://www.instagram.com/rayshappybirthdaybar",
  },
  {
    title: "Theater of Living Arts",
    summary: "Hole in the wall venue, built 1908. Indie and pop, mostly. Good for larger name indie artists, pop, & hip hop.",
    mainLink: "https://venue.tlaphilly.com/ ",
    facebook: "https://www.facebook.com/TLAPhilly/",
    insta: "https://www.instagram.com/tlaphilly",
  },
  {
    title: "WonderSpaces",
    summary: "“An evolving lineup of 16 extraordinary art installations with full bar.”",
    mainLink: "https://philadelphia.wonderspaces.com/",
    facebook: "https://www.facebook.com/wonderspacesphl/",
    insta: "https://www.instagram.com/wonderspacesphl",
  },
  {
    title: "Polish Club",
    summary: "“Phoenixville's Best Kept Secret since 2011. 400 capacity All Ages Music Venue Hosting National, International, Local and Underground Music.“ Polish American Citizens Club.",
    insta: "https://www.instagram.com/polishclubshows/",
  },
  {
    title: "The Rusty Nail",
    summary: "Dive bar about 45 mins away from Center City in Ardmore. Hosts a lot of local metal, harcore, and alt shows.",
    mainLink: "http://www.thenail1.com",
    facebook: "https://www.facebook.com/The-Rusty-Nail-199449793458045/",
  },
  {
    title: "Khyber Pass Pub",
    summary: "Pub at Penn's Landing that serves full meals, beer, and wine, with occasional local shows and electronic events.",
    mainLink: "https://www.khyberpasspub.com/",
    facebook: "https://www.facebook.com/KhyberPhilly",
    insta: "https://www.instagram.com/khyberpasspub/",
  },
  {
    title: "Tin Can Bar",
    summary: "“Tiki-ish hangout serving $5 Burgers, Craft Beer and Strong Drinks.“ Hosts local bands, with a lot of indie, shoegaze, emo, psychobilliy, soulful and funky vibes, etc.",
    mainLink: "https://www.tincanphilly.com/",
    facebook: "https://www.facebook.com/tincanphilly/",
    insta: "https://www.instagram.com/tincanphilly/",
  },
  {
    title: "Upstairs Abyssinia",
    summary: "A bar above Abyssinia, an Ethopian restaurant in West Philly. Cocktails, craft beer, ciders, lots of local band shows, comedy nights, karaoke, chess nights, drink and draw, craft nights, etc. A good spot for bluegrass bands.",
    mainLink: "https://www.abyssiniabarandrestaurant.com/bar",
    insta: "https://www.instagram.com/upstairsabyss/",
  },
  {
    title: "Pentridge Station",
    summary: "“West Philly Community Beer Garden Partnered w/ Dahlak Paradise. An exciting and ever-changing lineup of live music, local food vendors, and community events.“ Voted Philly's Favorite Beer Garden in 2024. Open May through Sept, annually.",
    mainLink: "https://www.pentridgestation.com/",
    insta: "https://www.instagram.com/pentridgestation/",
    facebook: "https://www.facebook.com/PentridgeStation/",
  },
  {
    title: "Philly Style Pizza & Grill",
    summary: "Temple area Pizza, hoagie, and burger shop opened 2021. Host local bands. Phat slices.",
    mainLink: "http://www.phillystylepizza.net/",
    insta: "https://www.instagram.com/phillystyle_pizza/",
  },
  {
    title: "Black Cat Tavern",
    summary: "Temporarily closed as of 2024. “A tiny ‘Mom & Pop’ Tavern, located in beautiful, deep South Philly, at the corner of 12th Street & Oregon Avenue.”  Another historic dive. Serves Polish food. Comedy nights. Announced closure Feb 2023, but then in May 2023 said ”on hiatus,” so may re-open.",
    mainLink: "http://www.blackcattavernphilly.com/",
    facebook: "https://www.facebook.com/BlackCatTavernPhilly/",
    insta: "https://www.instagram.com/blackcattavernphilly",
  },
  {
    title: "The Pharmacy",
    summary: "Coffee shop and gallery with shows. Indie, punk, hardcore, metal, electronic.",
    mainLink: "https://www.thepharmacyphilly.org/",
    facebook: "https://www.facebook.com/thepharmacycoffee/",
    insta: "https://www.instagram.com/thepharmacycoffee/",
  },
  {
    title: "Icebox Project Space",
    summary: "“Our mission is to facilitate projects that are a betterment of the community and to push for social change. We look to artworks, performances, videos, movement, and sound that have a sense of urgency and relevance in and around Philadelphia.“",
    mainLink: "https://www.iceboxprojectspace.com/",
    facebook: "https://www.facebook.com/iceboxprojectspace/",
    insta: "https://www.instagram.com/iceboxprojectspace",
  },
  {
    title: "Saint Vitus Bar",
    summary: "Music venue and bar in Brooklyn. 'Since day one Saint Vitus’ mission has been the same; provide a fun and uncompromising environment for the NYC underground music scene to hang out and create.' Lots of local bands. Hardcore, punk, post-punk, industrial, metal, alt.",
    mainLink: "https://www.saintvitusbar.com/",
    facebook: "https://www.facebook.com/saintvitusbar",
    insta: "https://www.instagram.com/saintvitusbar",
  },
  {
    title: "Bar XIII Delaware",
    summary: "“We are Delaware’s Home for Unique entertainment, Strange people and Live Music”. 40 mins out of Philly. Gothy place with punk, metal, industrial, etc. shows, burlesque, karaoke, sexy bingo, comedy nights. Home of Asylum 13, a “weekly 21+ kinky dark alternative dance party“.",
    mainLink: "http://barxiii.com/",
    facebook: "https://www.facebook.com/BarXIIIDelaware",
    insta: "https://www.instagram.com/barxiiidelaware/",
  },
];

export const MUSICBLUEGRASS = [
  {
    title: "Philly Bluegrass",
    summary: "“Philly Bluegrass’ mission is to promote the creation, performance, and appreciation of bluegrass and roots music in Philadelphia and its surrounding regions.“ Run event calendar, news posts, local band/arist directory, email newsletter.",
    mainLink: "https://phillybluegrass.com/",
    facebook: "https://www.facebook.com/PhillyBluegrass/",
    insta: "https://www.instagram.com/phillybluegrass/",
    suggestedFollow: "FB + check site calendar monthly",
  },
  {
    title: "Grateful Shed Studios",
    summary: "Maintains a list of applachia region musicians, bands, artists, vendors, and venues to follow and support. Also, a large music and arts festival list, for multi day camping festivals across the applachia region  , including festivals such as Dark Star Jubilee, Pocono 420 PA Cannabis Festival, and Weekend at Bertha's.",
    mainLink: "https://www.gratefulshedstudios.com/",
    facebook: "https://www.facebook.com/gratefulshedstudios/",
    suggestedFollow: "Check the festival list",
  },
  {
    title: "Kelly Center Bluegrass Jam",
    summary: "“Twice a month, fans of bluegrass music can enjoy – and participate in making – this traditional acoustic music. The Kelly Center Bluegrass Jam is hosted by Alfred Poor and scheduled for the first and third Tuesday of each month from 7 to 9 PM, and all are welcome.“",
    mainLink: "https://kellycenter.ticketleap.com/bluegrass-jam/details",
    facebook: "https://www.facebook.com/KellyCenterMusicArtsCommunity/",
    insta: "https://www.instagram.com/kellymusicforlife/",
  },
  {
    title: "Bluegrass Jams at Tin Can Bar",
    summary: "Every 3rd Wednesday, at the Tin Can Bar in Port Richmond. Hosted by Pat Farrell (guitar).",
    mainLink: "https://www.tincanphilly.com/",
    insta: "https://www.instagram.com/fatparrell/",
  },
  {
    title: "Society Hill Bluegrass Jam",
    summary: "“Every other Thursday. Beginners welcome--and encouraged. RSVP to vbralow@comcast.net for location.“",
    mainLink: "vbralow@comcast.net",
  },
  {
    title: "Old Time Jam at Gojjo",
    summary: "Every other Wednesday, meet on the second floor, out back if it is warm.",
    mainLink: "http://www.gojjos.us/",
  },
  {
    title: "Honkey Tonk on Hazel",
    summary: "Honky Tonk on Hazel House Shows. West Philly DIY house venue that hosts mostly bluegrass shows, plus occasional folk, country, etc. Indoor and outdoor shows. Seated venue.",
    insta: "https://www.instagram.com/honkytonkonhazel/",
  },
  {
    title: "World Cafe Live",
    summary: "“World Cafe Live is a nonprofit independent venue, typically open Tuesday through Saturday with a unique mix of concerts and events.“ Lots of bluegrass shows. Near UPenn.",
    mainLink: "https://worldcafelive.org/",
    facebook: "https://www.facebook.com/worldcafelive/",
    insta: "https://www.instagram.com/worldcafelive/",
    suggestedFollow: "Check site calender",
  },
  {
    title: "Haverford Music Festival",
    summary: "A single day music festival running it's 12 year (in 2024), usually end of August or start of September. Typically about a dozen acts, “plus great food, beer and wine garden, activities for the kids and fun for the entire family.“ Haverford, PA (40 mins out of Philly)",
    mainLink: "https://haverfordmusicfestival.org/",
    facebook: "https://www.facebook.com/profile.php?id=100063596379072",
    insta: "https://www.instagram.com/haverfordmusicfestival/",
  },
  {
    title: "Sellersville Theate",
    summary: "“Live music, comedy and more at Sellersville Theater.“ 45 mins out of philly. Hosts a lot of bluegrass bands.",
    mainLink: "https://www.st94.com/",
    facebook: "https://www.facebook.com/SellersvilleTheater/",
    insta: "https://www.instagram.com/sellersvilletheater/",
    suggestedFollow: "Check site calendar",
  },
  {
    title: "Philadelphia Folksong Society",
    summary: "61st Ann in 2024.A non-profit organization, we present the Philadelphia Folk Festival, one of the oldest and best known outdoor music festivals in North America! Dedicated to preserving the past, promoting the present, and securing the future of folk music and related forms of expression through education, presentation, and participation.  Folk Fest is mid August, annually, in Upper Salford Township (1 hr out of Philly)",
    mainLink: "https://pfs.org/",
    facebook: "https://www.facebook.com/PFSOfficialPage/",
    insta: "https://www.instagram.com/folksongsociety/",
    suggestedFollow: "Insta, which is mostly show listings",
  },
  {
    title: "Beardfest",
    summary: "“South Jersey's participatory celebration of creative energies, hosted by Out of the Beardspace.“ Three day music + camping fest in clearing in the woods, with a pond nearby. 12th annual as of 2024. Bluegrass, folk, blues, psychedelic rock, psychedelic pop, electronic, reggae, jazz, etc. Late June, annually. Hammonton, NJ (1 hr out of Philly).",
    mainLink: "https://www.facebook.com/beardfestmusicfestival/",
    facebook: "https://www.facebook.com/beardfestmusicfestival/",
    insta: "https://www.instagram.com/beardfestnj/",
    suggestedFollow: "Check mid spring for ann. date"
  },
  {
    title: "Into the Pines",
    summary: "2nd annual in 2024. “A diverse set of musicians, from bluegrass to brass. Focused on emerging bands and local musicians, combined with national touring talent, and well Established artists. Free workshops, local food vendors, artisans, open mic nite, lakeside camping, kid & pet friendly. Hammonton, NJ (1 hr out of Philly).“",
    mainLink: "https://intothepinesfestival.com/",
    facebook: "https://www.facebook.com/p/Into-the-Pines-Music-Festival-100093847680408/",
    insta: "https://www.instagram.com/intothepinesmusicfestival/",
  },
  {
    title: "The Sourland Mountain Festival",
    summary: "Every July. “The Sourland Mountain Festival brings the communities of the Central New Jersey region together and presents the best in musical talent, local food and drink, family fun - and a spectacular view. Proceeds from the Sourland Mountain Festival benefit the Sourland Conservancy. In Ringoes, NJ, 50 mins N of Philly. 20th year in 2025.",
    mainLink: "https://sourlandmountainfest.com/",
    facebook: "https://www.facebook.com/sourlandmountainfest/",
    insta: "https://www.instagram.com/sourlandmountainfest",
  },
  {
    title: "Berks Fiddle Fest",
    summary: "“Bring your instruments the 3rd Sunday of each September for the biggest and best music festival.“ Fleetwood, PA (1.5 hrs out of Philly).",
    mainLink: "https://www.fleetwoodboro.com/fiddlefest/",
    facebook: "https://www.facebook.com/berksfiddlefest/",
    insta: "https://www.instagram.com/berksfiddle/",
    suggestedFollow: "",
  },
  {
    title: "Wind Gap Bluegrass Festival",
    summary: "“In the southeastern frontier of Pennsylvania’s Pocono Mountain Range lies the small town of Wind Gap. each June, just up a winding tree-lined road from the town proper, magic fills the air!  It’s the magic of the annual Wind Gap Bluegrass Festival.“ 43rd annual in 2024. Free camping + showers, workshops. jamming, food and drink. Typically 2nd week of June. Wind Gap, Pennsylvania (1 hr 41 mins out of Philly)",
    mainLink: "https://www.windgapbluegrass.com/",
    facebook: "https://www.facebook.com/windgapbluegrass/",
    insta: "https://www.instagram.com/windgapbluegrass/",
  },
  {
    title: "Appalachian Fiddle & Bluegrass Association Festival",
    summary: "“Every summer a group of people come together in Wind Gap, Pennsylvania to create special moments reconnecting with old friends and making new ones. Every night campsites come alive with folks around a fire telling stories, singing and jamming. Across two stages nearly 30 bands will perform.“ 36th annual in 2024, generally 1st week of August. Wind Gap, Pennsylvania (1 hr 41 mins out of Philly).",
    mainLink: "https://www.afbawindgap.org/",
    facebook: "https://www.facebook.com/groups/462397237174208/",
    suggestedFollow: "Check early summer for festival date",
  },
  {
    title: "NEPA Bluegrass Festival",
    summary: "A four day bluegrass fest in Lazybrook Park, 17 years running as of 2024. The festival features family fun, two stages (traditional and progressive), food and craft vendors, a jam tent, workshops, and more. Free camping with weekend ticket. Generaly first long weekend of June. Tunkhannock, PA (2.5 hours out of Philly).",
    mainLink: "https://nepabluegrass.com/index.html",
    facebook: "https://www.facebook.com/nepabluegrassfestival/",
    suggestedFollow: "Check for festi date in mid spring",
  },
  {
    title: "Gettysburg Bluegrass Festival",
    summary: "“Founded in 1979, the historic Gettysburg Bluegrass Festival consists of a May & August festival each year and features the best names in bluegrass music! 4 days | 2 stages. Bluegrass, Camping, Vendors, Food Trucks, Beer, Wine & Good Times!“ Gettysburg, PA (2.5 hours out of Philly)",
    mainLink: "https://www.gettysburgbluegrass.com/",
    facebook: "https://www.facebook.com/gettysburgbluegrassfestival",
    insta: "https://www.instagram.com/gettysburgbluegrass/",
    suggestedFollow: "Check for dates in early spring and summer",
  },

];

export const MUSICCLASSICAL = [ {} ];

export const MUSICDIYCOLLECTIVESPHL = [
  {
    title: "Ratpie",
    summary: "“all things philly music scene related. interviews, film dumps, montages, artist showcases, + moreヽ(･ω･｡)ﾉ”",
    mainLink: "https://www.ratpiefriends.com/",
    insta: "https://www.instagram.com/ratpiefriends/",
  },
  {
    title: "Philly DIY Shows",
    summary: "A philly DIY show masterlist. Posting shows and events since October 2023. No venue or band tags, no posting of shows by dates, just pure and simple DIY fliers, posted as recieved. “DM ME UR EVENT & SHOW POSTERS“",
    insta: "https://www.instagram.com/philly_diy_music",
  },
  {
    title: "DIY Philly",
    summary: "Spiritual sucessor to House Show Philly, which stopped posting in 'Nov 2024. A Philly DIY show masterlist, shows for the coming weekend posted every Wednesday prior.",
    insta: "https://www.instagram.com/diyphilly/",
  },
  {
    title: "Philly-shows.org",
    summary: "“philly-shows.org is a community resource that facilitates community in the local music scene. The website attempts to list all music and concert events in Philadelphia in one consolidated location. Run by two people in Philly.“ Presents shows, also. Submit a show!",
    mainLink: "https://www.philly-shows.org/",
    insta: "https://www.instagram.com/phillyshowsdotorg/",
  },
  {
    title: "Philly Hardcore Shows",
    summary: "Hardcore show promotion group ran by legendary Philly promoter Joe Hardcore. Shows mostly at underground adjacent locations (ex. Ukie Club, Underground Arts, FU Church). Instagram is almost 100% fliers, from Hardcore promoters all over Philly.",
    mainLink: "https://www.philly-shows.com/",
    subLink: "https://www.facebook.com/PhillyHardcoreShows/",
    facebook: "https://www.facebook.com/Philly-Shows-Dot-Com-272843919423934/",
    insta: "https://www.instagram.com/phillyhcshows",
  },
  {
    title: "Dave Kiss Presents",
    summary: "“Puttin' on choice shows in Philly since 2009. Check out the 👇full calendar👇“ Lot's of local and regional artists. Many different genres. Regular booker for Kung Fu Necktie and Silk City.",
    mainLink: "https://davekisspresents.com/",
    insta: "https://www.instagram.com/davekisspresents/",
    facebook: "https://www.facebook.com/davekisspresents/"
  },
  {
    title: "A Bob Wilson Joint",
    summary: "Legendary Philly hardcore show promoter and harcore musician Bob Wilson throwing shows in Philly almost 10 years. Regular promoter at spots like FU Church, Cousin Danny's, Ukie Club, Bonk's Bar, The Rotunda, and Underground Arts. Runs record label Rebirth Records",
    mainLink: "https://rebirthrecords.bigcartel.com/",
    insta: "https://www.instagram.com/wilsonswar",
  },
  {
    title:"Philadelphia Psychedelic",
    summary: "“Philadelphia’s psychedelic music / art community and collective. Partnered with The Philly Stoop. Producer of shows, art marts, music fests, zine fests, gallery nights, open mics since Dec' 2021. Psy rock, jam bands, jazz fusion, funk, math rock, indie rock, ",
    insta: "https://www.instagram.com/philadelphiapsychedelic/",
    facebook: "https://www.facebook.com/profile.php?id=100093599613313",
  },
  {
    title: "Yarga Presents",
    summary: "The Haus may be gone, but Yarga is still putting together shows at spots like Cousin Danny's, Ortlieb's, Foto Club, and DIY spots like Howl's Castle, The Pouch, and Orpheus' Garden.",
    insta: "https://www.instagram.com/yargapresents/",
    facebook: "https://www.instagram.com/yargapresentsbackupaccount/"
  },
  {
    title: "Big Chair",
    summary: "“*creaks* Philadelphia based booking, reviews, and general music appreciation arts collective. Existent in various iterations for the better part of ten years.“ Mostly above ground, such as Ulana's, Vox Populi, PhilaMOCA, and Ortleib's, but also some DIY shows and collab w/ The Machine.",
    mainLink: "https://www.bigchairphl.com/",
    insta: "https://www.instagram.com/showsfromthebigchair/",
  },
  {
    title: "Jersey Show Scene",
    summary: "“Masterlist of jerseys music scene. notaflof - Woman ran @gabbyice_.“ A New Jersey DIY show listing account, posting weekly DIY show lists, similar to the style of House Show Philly.",
    insta: "https://www.instagram.com/jerseyshowscene/",
  },
  {
    title: "The Courtyard",
    summary: "“100% Unfiltered Underground. North Philly DIY Collective.” Throws electronic events, raves, flea markets, goth events with costume contents, food/drink, vendors. “Goth, Industrial, Tekno.” Warehouse events.",
    insta: "https://www.instagram.com/thecourtyardphl/",
  },
  {
    title: "DIY Music Philly!",
    summary: "House shows, open mics, local bands, seeking “some instrument player” posts, festivals. Well moderated and is very much DIY only. “For all Philly area musicians to share your music, promote shows, network with other musicians, and get help booking shows or finding bands in and around Philadelphia.“",
    facebook: "https://www.facebook.com/groups/72086746717/",
  },
  {
    title: "MD/DC/PA/NY/NJ/VA/DE Metal, Hardcore, Punk & More SHOWS",
    summary: "“A group collective for upcoming shows in the MD, DC, PA, NY, NJ, VA, DE area's. Please try to keep ALL posts SHOW related.” Above and underground shows. Very active and relatively low-key at 2.9k memebers.",
    facebook: "https://www.facebook.com/groups/147504269138636/",
  },
  {
    title: "würmhole produktions",
    summary: "Philly DIY music production group. Throw shows and events at Gray's Ferry Skatepark, Walmart Beach, and other DIY spots.",
    insta: "https://www.instagram.com/wurmhole_produktions/",
  },
  {
    title: "Save The Cat Touring",
    summary: "“Save The Cat is an artist management service and touring agency for lgbtq+ & bipoc artists. Devoted to booking at Safe spaces and ensuring quality DIY show practice, agents & organizers host and run Save The Cat affiliated concerts in Philadelphia, NYC, and upstate New York.”",
    mainLink: "https://linktr.ee/savethecattouring",
    facebook: "https://www.facebook.com/people/Save-The-Cat/100086974999093/",
    insta: "https://www.instagram.com/savethecattouring/",
  },
  {
    title: "Couple Ghouls",
    summary: "“booking shows in Philadelphia PA and helping friends with shows all over the east coast since like 2013.” DIY and pubs, etc.",
    mainLink: "https://www.coupleghouls.com/",
    facebook: "https://www.facebook.com/CoupleGhouls ",
    insta: "https://www.instagram.com/coupleghouls_/",
  },
  {
    title: "Pillow Princess Productions",
    summary: "“Booking. Recording. Releases. Formerly Paper Scissors Media.” Active show booking.",
    insta: "https://www.instagram.com/pillow.princess.productions/ ",
  },
  {
    title: "4333 Collective",
    summary: "“Music collective based out of Philadelphia. We host shows, make merch, and create community!” Their bands play a lot of DIY venues.",
    mainLink: "https://4333collective.net/ ",
    facebook: "https://www.facebook.com/4333collective/",
    insta: "https://www.instagram.com/4333collective/",
  },
  {
    title: "Rubbish Records",
    summary: "Philly local label. Punk, ska, folk punk, indie punk, garage punk, skate punk, etc. Posts lots of shows at a mix of DIY and above ground spots.",
    mainLink: "https://rubbishrecords.com/ ",
    facebook: "https://www.facebook.com/RubbishRecordsSPP/",
    insta: "https://www.instagram.com/rubbish.records/",
  },
  {
    title: "Relapse Records",
    summary: "Located in Upper Darby, PA and started in a Colorado basement. Grindcore, crust punk, death metal, hardcore, sludge metal, black metal, folk metal, and more, with local bands such as Devil Master and Baroness, and other noteable worldwide bands, like, Zouo, Wolves in the Throne Room, G.I.S.M., Iron Reagan, Chris Connelly, and Merzbow. PA is truly blessed to house this label. Check them out!",
    insta: "https://www.instagram.com/relapserecords",
    facebook: "https://www.facebook.com/RelapseRecords/",
    mainLink: "https://www.relapse.com/",
    subLink: "https://relapserecords.bandcamp.com/",
  },
  {
    title: "Rat Milk Collective",
    summary: "“DIY punk, grind, thrash, fastcore, pv, death, etc. booking collective, Eastern PA, Est. 2011. DIY Tape Label, Est. 2015.” “punk / grind / thrash / fastcore / pv / death.” Show postings, mostly. Sometimes new albums, etc.",
    facebook: "https://www.facebook.com/ratmilkcollective/",
    insta: "https://www.instagram.com/ratmilkcollective/ ",
  },
  {
    title: "Virtua94",
    summary: "Local electronic label. Vaporwave, Synthwave, Chillwave, D&B, Chiptune, Jungle, Rave, etc.. Throws dance events and raves. Social media not updated. Check their site.",
    mainLink: "https://virtua94.com/",
    facebook: "https://www.facebook.com/virtua94",
    insta: "https://www.instagram.com/virtua94",
  },
  {
    title: "REFRESH",
    summary: "Philadelphia dance event series. Electronic shows, mostly at The 700, a Northern Liberties bar w/ upstairs loft dance area. Hyperpop, ambient, glitch, dance, techno, club, dream pop, etc.",
    insta: "https://www.instagram.com/refresh.phl",
  },
  {
    title: "Tuna Collective",
    summary: "“Record label. up to no good. handmade in philly.” Musicians/bands play DIY venues.",
    subLink: "https://www.youtube.com/watch?v=0Lal5Ss_InM",
    insta: "https://www.instagram.com/tuna.collective/",
  },
  {
    title: "Kulture Cru",
    summary: "“👽Philly Rav3 3v3nts🎈 Intimate Vibes with BIG IMPACT and a close-knit, community-focused vibe. No commercialized corporate overload. Focus on underground talent.“ Underground raves, held everywhere from warehouses, to fields, to forests, to farmhouses.“",
    mainLink: "https://kulturecrurecords.com/subkulturefest",
    facebook: "https://www.facebook.com/p/Kulture-Cru-100089922739185/",
    insta: "https://www.instagram.com/kulture_cru/",
  },
  {
    title: "Kame House",
    summary: "“Philadelphia D.I.Y. art initiative. music venue, documentaries, art organization and creative space.” Former basement venue. Philly rave scene supporter. YT is 'Documentaries, live show clips, interviews, and other methods of publicizing the happenings of the Philadelphia underground music scene'",
    mainLink: "https://www.kamehousemedia.com/",
    subLink: "https://www.youtube.com/@kamehousephl",
    insta: "https://www.instagram.com/kamehouse.phl/ ",
  },
  {
    title: "Eclectica Division",
    summary: "“We are a women-led DIY management collective + record label. We manage artists, book & curate shows, coordinate album & single releases, develop press plans, provide consultation, and tour manage.”",
    mainLink: "https://www.eclecticadivision.com/",
    facebook: "https://www.facebook.com/eclecticadivision",
    insta: "https://www.instagram.com/eclecticadivision/",
  },
  {
    title: "ROWTONE",
    summary: "“philly shows booking.“ Books at above ground and DIY venues.",
    mainLink: "https://rowtone.com/",
    subLink: "https://www.youtube.com/@ROWTONE",
    insta: "https://www.instagram.com/rowtonephl/",
  },
  {
    title: "Naked Lightbulb",
    summary: "“sad bitch beauty queen. dm for booking.“ Books at DIY and above ground venues, like Cousin Danny's.",
    insta: "https://www.instagram.com/naked.lightbulb/",
  },
  {
    title: "Bread Box Booking",
    summary: "“Art Gallery, music venue, and event space” of summer 2023 turned DIY venue booking following space lease termination.",
    insta: "https://www.instagram.com/breadboxphilly/",
  },
  {
    title: "Minimal Chaos Collective",
    summary: "“Park Parties, Fire Jams, & Community Engagement.“ Philly Burner collective. Fire arts. Tight ropes. DJs. Ice skating. Host regular parties and annual 4/20 Potluck and Park Party.",
    subLink: "https://www.eventbrite.com/o/minimal-chaos-collective-103077852261",
    facebook: "https://www.facebook.com/MinimalChaosCollective",
    insta: "https://www.instagram.com/minimal_chaos_"
  },
  {
    title: "Born Loser Records",
    summary: "“A label from Philly. Something for everyone.” Small label with about 50 artists. Artist management. US distribution. ",
    mainLink: "https://bornlosersrecords.com/",
    subLink: "https://bornlosersrecords.bandcamp.com/",
    insta: "https://www.instagram.com/bornlosersrecords/",
  },
  {
    title: "The Tabernacle",
    summary: "Books shows and makes flyers. Used to also be a N Philly house venue, but not longer throws shows at house.",
    insta: "https://www.instagram.com/tabernaclebooking/",
  },
  {
    title: "WHIP Radio",
    summary: "“Temple University’s student-run & student-minded radio station.”",
    mainLink: "http://www.whipradiotu.com/",
    subLink: "https://www.iheart.com/live/whip-radio-5254/",
    facebook: "https://www.facebook.com/whipradiotu/",
    insta: "https://www.instagram.com/whipradio/",
  },
  {
    title: "Cosmic Art Studios",
    summary: "“Creative Community Space in Pottstown, Pa.” (1 hr out of Philly). Tea bar. Dance events. Local markets. Lounge. Balcony with bar. Another event space on second floor. Art nights. Monthly “shindigs.” Local music. Yoga, sound healing, art classes, open mic, drum circles.",
    mainLink: "https://www.cosmicarts.org/",
    facebook: "https://www.facebook.com/cosmicartspa",
    insta: "https://www.instagram.com/cosmic_art_studio/",
  },
];

export const MUSICDIYCOLLECTIVESSC = [
];

export const MUSICDIYGENPHL = [
  {
    title: "hate5six",
    summary: "“Live music archivist. Tape liberator. Ex-data scientist. Don't stand on a silent platform. Free Mumia Abu Jamal and Leonard Peltier.” Hundreds of videos of local bands at local spots.",
    mainLink: "https://hate5six.com/",
    subLink: "https://www.youtube.com/user/hate5six/videos",
    facebook: "https://www.facebook.com/hate5six",
    insta: "https://www.instagram.com/hate5sixofficial/",
    suggestedFollow: "Check their youtube to find good local bands",
  },
  {
    title: "mid-atlantic underground Discord",
    summary: "House and venue shows posted, general chat, newsfeed, etc.. Pretty active. Good place to meet people online, before in person, if a shy person. Philly, NJ, NYC region.",
    mainLink: "https://discord.com/invite/8mkvVYw48z",
    suggestedFollow: "Discord",
  },
  {
    title: "West Philly Porchfest",
    summary: "“An annual do-it-yourself music festival featuring musicians of all kinds playing free shows on porches throughout the neighborhood. Created by and for West Philadelphia residents, and to celebrate the community’s broad musical and cultural diversity.” Running since 2016. Over 100 spots in 2023! Typically first Saturday of June.",
    mainLink: "https://westphillyporchfest.com/",
    facebook: "https://www.facebook.com/westphillyporchfest",
    insta: "https://www.instagram.com/westphillyporchfest",
    suggestedFollow: "Check site in early summer for annual date",
  },
  {
    title: "Cherry-Veen Zine",
    summary: "“A Philly Music Scene Zine.” DIY and small venue focused. Also record local sessions.",
    mainLink: "https://www.cherryveenzine.com/",
    subLink: "https://www.youtube.com/channel/UCrLM87WTuWRgPW6uD9vHnNw",
    facebook: "https://www.facebook.com/cherryveenzine",
    insta: "https://www.instagram.com/cherryveenzine/",
    suggestedFollow: "Insta + site for zines",
  },
  {
    title: "Global Rock Underground",
    summary: "“Booking Coordinator. Promoter. Session Drummer. Photographer.” Philly. Put together multiple 200+ artist “Philly’s Best” mixes on Spotify.",
    mainLink: "https://www.globalrockunderground.com/",
    subLink: "https://open.spotify.com/playlist/1jkoqs2bZo3mY1RmY6VYG4",
    facebook: "https://www.facebook.com/globalrockunderground",
    insta: "https://www.instagram.com/globalrockunderground/",
  },
  {
    title: "Camp Punksylvannia",
    summary: "“PA’s ultimate punk rock summer camp experience.“ Camping music festival. 5th year in 2024. 3 days. 4 stages. 50 bands. Punk, folk punk, hardcore, hardcore, bluegrass, ska, etc. Bring a tent, but leave the bed bugs at home. June 20-22, 2025. Outisde of Pittsburgh",
    mainLink: "https://www.camppunksylvania.com",
    facebook: "https://www.facebook.com/CampPunksylvania",
    insta: "https://www.instagram.com/camppunksylvania",
    suggestedFollow: "Check site in early summer for annual date",
  },
  {
    title: "Two Piece Fest",
    summary: "“A celebration of duos.“ Drums and guitar? Synth & Guitar? Keyboard and violin? Anything band goes, as long as they are a duo. 18 years and running in 2025. Diverse set of music genres.",
    mainLink: "http://twopiecefest.com/",
    subLink: "https://twopiecefest.bandcamp.com/",
    facebook: "https://www.facebook.com/twopiecefest/",
    insta: "https://www.instagram.com/twopiecefest",
    suggestedFollow: "Check for ann. fest date mid winter."
  },
  {
    title: "DoDIY",
    summary: "“A catalog of DIY event spaces & organizers from around the world + resources on the DIY ethos. Since 2007.” Does monthly “site report” from some spot. A lot of PNW spots, but also Philly (about ½ as much as PNW).",
    mainLink: "http://www.dodiy.org/ ",
    facebook: "https://www.facebook.com/dodiynews",
    insta: "https://www.instagram.com/dodiynews/",
  },
  {
    title: "Airlock",
    summary: "“love pressure atmosphere ⚧️🪩💌“ A re-occuring electronic event. 10am to sunrise deals w/ DJs all night. Outdoor events, also.",
    insta: "https://www.instagram.com/airlock.phl/",
  },
  {
    title: "Break Free Fest",
    summary: "“Hardcore and Punk fest creating space for POC communities in Philly (Lenape land)“ Annual fest, usually in early summer or late spring. Also has done art market fundraisers, Ukie Club shows.",
    facebook: "https://www.facebook.com/breakfreefest/",
    insta: "https://www.instagram.com/breakfreefest/",
    suggestedFollow: "Check insta in mid spring for ann. date.",
  },
  {
    title: "Punk Rock Flea Market",
    summary: "“The Punk Rock Flea Market is the place to shop for awesome clothing, records, crafts, art, and most of all old punk junk! 200 vendors per day, different vendors each day.“ One market each season.",
    mainLink: "https://www.phillyprfm.com",
    facebook: "https://www.facebook.com/PhillyPRFM",
    insta: "https://www.instagram.com/phillyprfm",
    suggestedFollow: "Email newsletter. Join it on their site.",
  },
  {
  title: "Trans Art Market",
  summary: "“Philly art market exclusively highlighting Trans, Non-Binary and Gender Expansive Artists“ Since 2020, at various spots around the city. Some years occurs in spring and winter. Some years just in winter.",
  mainLink: "https://transartmart.weebly.com/",
  facebook: "https://www.facebook.com/transartmart",
  insta: "https://www.instagram.com/transartmart/",
  suggestedFollow: "Check Insta in late fall and and early spring for dates",
},
];

export const MUSICDIYSPOTSPHL = [
  {
    title: "Cambria House",
    summary: "“DIY music space, art collective, & home to hundreds in Kensington since 2006.” Rare shows. A-Team.",
    insta: "https://www.instagram.com/cambriaphl/",
  },
  {
    title: "The Sanctuary",
    summary: "West Philly studio in a basement. Hardcore, punk, death metal, black metal, metal punk, d-beat, punk n roll, thrash punk, raw punk, rock, dungeon synth, grindcore, crust punk, noise, etc.. Gutteral growls and weeping guitars in the dungeon.",
    facebook: "https://www.instagram.com/the_sanctuary_phl",
  },
  {
    title: "The Meadow",
    summary: "W Philly House venue around since about 2017. “Inclusive artist collective, shop, record label & performance space in West Philly.”",
    subLink: "https://www.youtube.com/channel/UChU2p8WGo1yJcsz9wPvCa2Q",
    insta: "https://www.instagram.com/themeadowphl/",
  },
  {
    title: "Luigi’s Mansion",
    summary: "W Philly house venue since 2019. Dance parties, punk, indie, noise pop, etc. Lots of art rock, but metal and hardcore, too. “suffer in the basement...“",
    subLink: "https://www.youtube.com/channel/UC5fxGTTkZwBkIygnNdx0hQA",
    facebook: "https://www.facebook.com/LuigisMansionPHL",
    insta: "https://www.instagram.com/luigismansionphl",
  },
  {
    title: "Onion Transfer",
    summary: "West Philly house venue. Formerly Lucky Aide, Couchtown, Hole Foods, Cosmos, etc.. “Multi purpose art empowerment space. Cease and desisted West Philly DIY venue, now legally distinct",
    insta: "https://www.instagram.com/onion.transfer/",
  },
  {
    title: "The Pouch",
    summary: "West Philly house venue. “Basement venue, down a flight of stairs.” Frequent shows, even in winter, and staple of the Philly DIY scene.",
    insta: "https://www.instagram.com/thepouchphl",
  },
  {
    title: "Tralfamadore",
    summary: "South Philly house venue. “Performance and Recording Space since 2015”.",
    insta: "https://www.instagram.com/tralfamadore.southphilly/",
  },
  {
    title: "The Machine",
    summary: "House venue and art collective. Since 2021. Very active, putting together consistent stream of DIY and above ground shows. A key promoter for local electronic shows.",
    twitter: "https://twitter.com/themachinephl",
    insta: "https://www.instagram.com/themachinephilly",
  },
  {
    title: "The Stoop",
    summary: "North Philly warehouse spot. “hosting + booking Art Marts. DIY north philly block concerts. women operated” Art marts hosted at various DIY venues. Partnered with Philadelphia Psychedelic and home to many psy shows.",
    insta: "https://www.instagram.com/philly.stoop/",
  },
  {
    title: "The Coach Room",
    summary: "Basement DIY spot,that also does rooftop shows. Indie rock, folk rock, psy rock, pop, rap, electronic, jazz, etc. Indie Sleaze Night, Spookyfest, Comedy Show. Est Oct' 2022.",
    insta: "https://www.instagram.com/thecoachroomphilly/",
  },
  {
    title: "Orpheus’ Garden",
    summary: "“🌱 🌿 South Philly DIY HOUSE Venue 🍃☘️ POC / LGBT+ Ran EST. 2021” Basement venue.",
    insta: "https://www.instagram.com/orpheus.garden/",
  },
  {
    title: "The Backyard",
    summary: "An abando two story + attic building with a big backyard shows are thrown at. No social media or site.",
  },
  {
    title: "Walmart Beach",
    summary: "Big abandoned pier by your friendly local Walmart where people throw shows, dance parties, raves, eat rotisserie chickens, etc.",
    mainLink: "https://www.youtube.com/watch?v=tPvTyEov2ZE",
  },
  {
    title: "Over Yonder",
    summary: "North Philly spot. “Smog Emissions Check Station. philly diy subterranean fun zone.“ Used to be Blacklight. Started in Oct’ 22, following Blacklight closure.",
    insta: "https://www.instagram.com/overyonder.phl/",
  },
  {
    title:"Angies’s in West",
    insta: "https://www.instagram.com/angieswestphilly", 
    summary: "West Philly house venue. Indie rock, post-punk, noise rock, garage rock, punk, noise pop, hyperpop, breakcore, DnB, cybergrind, etc. Occassional benefit shows (ex. Palestine, trans mutual aid). Since July 2023",
  },
  {
    title: "Fumblin’ Perkins’ Underpass",
    summary: "“Port Richmond's finiest.“ Accoustic, indie rock, psy rock, conscious hip-hop, math rock, synthwave, etc. First show March 2022.",
    insta: "https://www.instagram.com/fumblinperksphl/",
  },
  {
    title: "Howl’s Castle",
    summary: "W Philly venue started summer '22'. “Hey everyone, we're a new house show spot in West Philly near Drexel. Our name is inspired by the Studio Ghibli film, 'Howl's Moving Castle.' We offer a space for local artists to perform. We host all kinds of shows!”",
    subLink: "https://www.youtube.com/channel/UCQXu8LCQUZqXA7lgc7Ocmbg",
    insta: "https://www.instagram.com/howls_castle_phila/",
  },

  {
    title: "Hush House",
    summary: "South Philly house venue w/ first show NYE 21. Can be a long time in between shows.",
    insta: "https://www.instagram.com/hushhousephilly",
  },
  {
    title: "The Kitchenette",
    summary: "House venue. First show March '22. “Smog Emissions Check Station. East falls unplugged monthly. Quiet sets for loud bands.“",
    insta: "https://www.instagram.com/kitchenette.phl/",
  },
  {
    title: "God's Automatic Body & Spa",
    summary: "“The mysteries of the universe unvieled through the creation of and sharing of art. DIY arts and events in west philly.“ Shows, DJs, dance parties, karaoke, line dancing, game shows, bingo, haircuts, Philly Black Workers Project, Palestinian Refugee, and other solidarity fundraising shows, Pee Wee's Gay House Costume Contenst. Started 2023.",
    mainLink: "https://gods.gay/",
    insta: "https://www.instagram.com/gods.automatic.body/",
  },
  {
    title: "The Underworld",
    summary: "“just a couple of wet rats in charge of a philly diy house venue!!! Queer owned & operated.” Basement venue. Somtimes uses show cover as donations for groups like Treehouse Books and Abortion Lib Fund of PA. First show Sept ‘22. Mixes it up, with things like art mart, masquerade ball, etc..",
    insta: "https://www.instagram.com/theunderworldphilly/",
  },
  {
    title: "The Snake Pit",
    summary: "Anarchist and punk run house venue. Lots of hardcore, crossover, etc. “Ask a ghoul.” Insta vanished, but still throwing shows.",
    subLink: "https://www.youtube.com/watch?v=ZX0YjeTEgbc",
    insta: "https://www.instagram.com/snakepit_philly/",
  },
  {
    title: "The Wvrmhole",
    summary: "West Philly house venue. ”Music and Dance in Filthadelphia” Since Oct '22. Has thrown other events also, such as 'pop up club and kink party.'",
    insta: "https://www.instagram.com/wvrmhole.philly/",
  },
  {
    title: "Our House",
    summary: "”West Philly | NOT a Psy-op. Monthly Music. est. 2023.” Basement spot started spring 2023. Primal emo noises and post-hardcore cacophony, amongst other sounds.",
    insta: "https://www.instagram.com/ourhousephl/",
  },
  {
    title: "The Roof",
    summary: "”West Philly rooftop shows. The Roof is a west philly house venue featuring pristine views of the city coupled with some of the best music philly has to offer.” Started Spring 2023.",
    insta: "https://www.instagram.com/theroof.phl/",
  },
  {
    title: "MoMA 2",
    summary: "“south philly shows“ Started spring 2023. Indie, post-rock, punk, hardcore, singer songwriter, grune, slowgaze, etc.",
    insta: "https://www.instagram.com/moma2phl/",
  },
  {
    title: "The Garden House",
    summary: "“The Garden House is a new Queer Cottage. Core DIY folk,indie,lofi,Shoegaze.“ Main room for performances. Garden outside for chill. Basement for poetry and acoustic shows. Studio Ghibli Pajama Jam. Femme Magic Night.",
    insta: "https://www.instagram.com/tgh_philly/",
  },
  {
    title: "Cantaloupe Kingdom",
    summary: "“Welcome to South Philly's newest house venue. We are an inclusive no hate space catering to diy musicians! Formerly JHQ.” Basement venue started late Aug ‘22.",
    insta: "https://www.instagram.com/cantaloupekingdom/",
  },
  {
    title: "The Kensington Palace",
    summary: "Warehouse loft venue with a decent amount of space. A lot of hardcore, punk, emo, screamo, shoegaze, post-hardcore, etc.",
    insta: "https://www.instagram.com/the_palace_215/",
  },
  {
    title: "The Kennel",
    summary: "“trans-operated west philly diy venue, est. Feb 2024. Shows are monthly.“ Basement venue. Philly shitgaze, Philly basketballshots core, cybergrind, moemoe anime and vidya tracks, post-hardcore, screamo, noise, etc.",
    insta: "https://www.instagram.com/kennelphilly/",
  },
  {
    title: "Mini Mart",
    summary: "West philly house venue. Shows, art mart, DIY drag show extravaganza. Experimental, electronic, noise, rock, ambient, no wave, hardcore, hip hop, shoegaze, dance.",
    insta: "https://www.instagram.com/minimart.phl/",
  },
  {
    title: "Moth House",
    summary: "“Music experiences in South Philly. First Friday Function - March thru October.“ Singer songwriter, country, folk, folk punk, etc. Acoustic instruments.",
    insta: "https://www.instagram.com/welcometomouthhouse/",
  },
  {
    title: "The Silver Age HQ",
    summary: "“The Silver Age is a group of movers and shakers and candlestickmakers. Our headquarters, or the Headquarters as we like to call it, is just as fun as a ball pit. Produces local music and DIY Fun House zine. Est Dec 2023. Nice mix of music genres.",
    mainLink: "https://thesilverage.com/",
    insta: "https://www.instagram.com/the.silver.age.hq/",
  },
  {
    title: "The Rug Club",
    summary: "Queer- and femme-run west philly house venue. Indie, singer-songwriter, folk, shoegaze, etc.. Multiple benefits for Palestine.",
    insta: "https://www.instagram.com/rugclubphl/",
  },
  {
    title: "The Zapp Factory",
    summary: "“Underground Philly Music & Art Studio. ⚡️Home of The Zapp Test⚡️. A hub for creatives and artistic communities.“ Psychedelic rock, reggae, mathcore, etc.",
    insta: "https://www.instagram.com/zappfactory/",
  },
  {
    title: "LittleBigPlanet",
    summary: "“a south philly basement venue hidden in plain sight. limited capacity special engagements. no cops, no terfs, no guns, no creeps.“ Est. Feb 2023.",
    insta: "https://www.instagram.com/lbp.phl/",
  },
  {
    title: "Nightmair and Percy’s",
    summary: "“south philly folk venue. no zionists. no bigots. no bullshit. “ New venue started April 2024.",
    insta: "https://www.instagram.com/nightmairandpercysphl/",
  },
  {
    title: "Bond Villain",
    summary: "Long running North Philly warehouse venue. Dreampop, post-punk, reggae, techno, analog VJ's, and more. Food, drinks, karaoke, dodgeball, and good vibes. Infrequent shows. Usually still has annual Halloween shows.",
    insta: "https://www.instagram.com/bond.villain/",
  },  
  {
    title: "American Legion Post 2014",
    summary: "Located in Upper Darby, PA, 30 mins out of Center City. Hosts shows, mostly punk and harcore. No website for shows there, but a variety of Promoters, such as Bob Wilson Joint, have thrown shows there."
  },
  {
    title: "The Caf",
    summary: "“New DIY venue in South Philly“ Started March 2024 with a Star Wars Costume May the 4th show.",
    insta: "https://www.instagram.com/thecafphilly/",
  },
  {
    title: "The Shire",
    summary: "“North Philly House Show Venue 🧙‍♂️ 🌳“ New venue stated April 2024.",
    insta: "https://www.instagram.com/theshire.phl/",
  },
  {
    title: "The Litterbox",
    summary: "“brewerytown house venue.“ New venue in 2024, first show in April, then another one in October.",
    insta: "https://www.instagram.com/litterbox.phl/",
  },
  {
    title: "Kinda Pink",
    summary: "“North Philly House Venue“ Opened Sept 2023. All types of genres, including rap. Jam sessions.",
    insta: "https://www.instagram.com/kindapink.phl/",
  },
  {
    title: "The Cabin",
    summary: "W Philly house venue. Home of etc. “We hope to be a collaborative effort in making / sharing creative events: yard sales, themed events, art swaps, shows, zine fairs, etc (get it?!), we are elated for this new era.“ Started in '22. ",
    insta: "https://www.instagram.com/etc.phl/",
  },
  {
    title: "Neon Jungle",
    summary: "Cecil Records basement venue. Successor of The Sink, which closed Aug ‘22. Innactive since spring 2024.",
    insta: "https://www.instagram.com/neonjunglephilly/",
  },
  {
    title: "Detour Philly",
    summary: "“west philly house venue in west philly. we host cool bands and believe in being true to ourselves, beyond boundaries.” Started summer '21. Infrequent shows.",
    insta: "https://www.instagram.com/DetourPhilly/",
  },
  {
    title:"Titus’ Funeral Home",
    summary: "“south philly’s #1 gig-funeral-fish emporium.“ Flat venue. First show Dec '22, and only throws about one or two shows a year, so far. Innactive since spring 2024.",
    insta: "https://www.instagram.com/titus.funeralhome/",
  },
  {
    title: "Devil’s Garden.",
    summary: "“a queer/trans/polyam led rooftop party & music venue in South Philly. Come dance, jam, grow, play & find community! BYOB”",
    insta: "https://www.instagram.com/devilsgardenphilly/",
  },
  {
    title: "Stupid Baby’s House",
    summary: "“diy venue mantua phl. owned and operated by someone born yesterday.“ Booker, also. F.K.A The Psychedelic Jungle.",
    insta: "https://www.instagram.com/stupidbabyphl/",
  },
  {
    title: "Candy Jail",
    summary: "East Kensington / Fishtown basement spot. *booking closed* / on smoke break 🚬 as of May 2024.",
    insta: "https://www.instagram.com/candyjailphl/",
  },
  {
    title: "The Ankle Pit",
    summary: "“𝚚𝚞𝚎𝚎𝚛 𝚏𝚘𝚌𝚞𝚜𝚎𝚍 𝚜𝚑𝚘𝚠𝚌𝚊𝚜𝚎 𝚒𝚗 𝚠𝚎𝚜𝚝 𝚙𝚑𝚒𝚕𝚕𝚢, 𝚋𝚛𝚘𝚞𝚐𝚑𝚝 𝚝𝚘 𝚢𝚘𝚞 𝚋𝚢 𝚖𝚒𝚕𝚘 𝚗 𝚖𝚓. 𝚃𝚑𝚎𝚛𝚎𝚜 1 𝚌𝚊𝚝 𝚑𝚎𝚛𝚎, 𝚊𝚗𝚍 𝚠𝚎 𝚑𝚊𝚟𝚎 𝚑𝚟𝚊𝚌. 𝙳𝚒𝚍 𝚠𝚎 𝚖𝚎𝚗𝚝𝚒𝚘𝚗 𝚠𝚎'𝚛𝚎 𝚚𝚞𝚎𝚎𝚛“ NOTAFLOF. Started May 2023. Basement spot.",
    insta: "https://www.instagram.com/theanklepit/",
  },
  {
    title: "Legoland",
    summary: "North Philly house venue. Closed late summer ‘22 then reopened at new location. Dance events and house raves. Hip hop, also. Has thrown warehouse events too.",
    insta: "https://www.instagram.com/legolandphl",
  },
  {
    title: "Big Farma",
    summary: "“West Philly House Venue!” Started summer 2022. Run by 4333 Collective.",
    subLink: "https://www.instagram.com/4333collective/ ",
    facebook: "https://www.facebook.com/4333collective/ ",
  },
];

export const MUSICDIYSPOTSSC = [
  {
    title: "The Pumpkin Patch",
    summary: "“Haunted House. Come see a show!!”",
    insta: "https://www.instagram.com/the.pumpatch/",
  },
];

export const MUSICDIYSPOTSPITTS = [
  {
    title: "Eden",
    summary: "“DIY house venue in Oakland, Pittsburgh. Previously Ba Sing Se / Glove World.” Routine Cyberia house raves.",
    facebook: "https://www.facebook.com/eclcts",
    insta: "https://www.instagram.com/actualeden/",
  },
  {
    title: "The Deli",
    summary: "Started summer '22. Oakland area house venue. Occasional electronic shows as well as the usual. Punk venue.",
    insta: "https://www.instagram.com/thedelipgh/",
  },
  {
    title: "La Don Casa",
    summary: "“South Side Show House.” Punk and art rock venue started late '22.",
    insta: "https://www.instagram.com/thedelipgh/ ",
  },
  {
    title: "Echo Chamber",
    summary: "House venue with electronic, indie, art rock shows and a neon highlighted blacklight setup. ",
    insta: "https://www.instagram.com/echochamberpgh/ ",
  },
  {
    title: "Hammer House",
    summary: "West Oakland area house venue. ",
    insta: "https://www.instagram.com/hammer.house.pgh/ ",
  },
  {
    title: "The Mystery Shack",
    summary: "Started Jan ‘22. Shows as well as movie nights. Based “still posting printed posters on telephone poles” hangers.",
    insta: "https://www.instagram.com/mysteryshackpgh/",
  },
  {
    title: "Venia",
    summary: "First show Dec '22. “Experimental pop, breakcore, hyperpop, post punk n more.”",
    insta: "https://www.instagram.com/veniapgh/ ",
  },
];

export const MUSICELECTROINDUST = [
  {
    title: "Phillygoth",
    summary: "A nice goth events calendar, plus local venue list and bands/DJs list.",
    mainLink: "https://phillygoth.net/",
    facebook: "https://www.facebook.com/groups/phillygoth",
    insta: "https://www.instagram.com/phillygoth/",
    suggestedFollow: "Site Calendar + Facebook",
  },
  {
    title: "NJ Gothic",
    summary: "Calendar site for goth shows, events, etc. in NJ, PA, and NYC",
    mainLink: "https://sites.google.com/site/njgothic/",
    suggestedFollow: "Check calendar monthly",
  },
  {
    title: "Sanctorium Philly",
    summary: "”A Goth/Industrial club event @ The Fire. It takes place tri-annually.” Throw additional goth/industrial shows, as well.",
    facebook: "https://www.facebook.com/SanctoriumPhilly/",
    suggestedFollow: "Facebook",
  },
  {
    title: "Philadelphia Underground Techno",
    summary: "“Let's support the advancement of all techno, industrial and EBM warehouse parties, along with regular venues in this city.”",
    facebook: "https://www.facebook.com/groups/2030812043858866/",
    suggestedFollow: "Facebook",
  },
  {
    title: "Dancing Ferrit Concerts",
    summary: "Announcements for Dancing Ferrit promoted shows (ex. Front 242, Nitzer Ebb) and dance parties.",
    facebook: "https://www.facebook.com/groups/1746297245598673",
    insta: "https://www.instagram.com/dfconcerts/",
    suggestedFollow: "Insta",
  },
  {
    title: "EDM Train",
    summary: "Above ground EDM calendar",
    mainLink: "https://edmtrain.com/philadelphia-pa",
    suggestedFollow: "Check site calendar month",
  },
  {
    title: "Philadelphia Techno Movement",
    summary: "Above and underground electronic music events group.",
    facebook: "https://www.facebook.com/groups/philadelphiatechnomovement/",
    suggestedFollow: "Facebook",
  },
];

export const MUSICHIPHOP = [
  {
    title:"The Gathering",
    summary: "”(almost) every last Thursday. Established in 1996, The Gathering is the longest/strongest-running truly Hip Hop event in Philly. The Gathering IS b-boys/b-girls, pop-lockers, emcees, graffiti writers, DJs, men, women, and children of all ages enjoying an organic, community-based celebration of the struggle, the Love, and the culture of Hip Hop. DJs spin Hiphop, breaks, and funk all night, and there are open cyphas, a tag wall, and a featured performance and graffiti panel each month.”",
    mainLink: "https://www.therotunda.org/event_category/the-gathering",
    facebook: "https://www.facebook.com/TheGathering215/",
    insta: "https://www.instagram.com/thegathering215/",
    suggestedFollow: "Insta"
  }
];

export const MUSICMETAL = [
  {
    title: "Philly Metal",
    summary: "Email newsletter + website. Very well updated. All you really need for metal shows in Philly. If join email list, will get weekly email with newly added shows. Local metal and big shows both posted.",
    mainLink: "https://phillymetal.net/",
    facebook: "https://www.facebook.com/groups/120015764787707",
    suggestedFollow: "Email mailing list",
  },
];

export const MUSICREGIONAL = [
  {
    title: "'Philly Bands + South/Central NJ' List",
    summary: "A list of over 250 local bands, compiled by DIYTourNetwork Philly",
    mainLink: "https://docs.google.com/spreadsheets/d/1EixCZ6GOw4WPGODa2CDEPfbAXXyTeveD_qiCfqeLxgY/edit#gid=0",
    subLink: "https://diytournetwork-philly.tumblr.com/"
  },
  {
    title: "Philadelphia Bandcamp Albums",
    summary: "Check out local Philadelphia artists on bandcamp. Sort by 'best selling', 'new arrivals', or 'suprise me'.  ",
    mainLink: "https://bandcamp.com/discover?loc=4560349",
  },
  {
    title: "Global Rock Underground:  Best of Philly & NJ Playlists",
    summary: "“Active artists from the Philadelphia area. Updated quarterly. Submission form on globalrockunderground.com. 150 songs, 8.5 hours long for Philly. 100 songs, 5 hours long for NJ.“",
    mainLink: "https://open.spotify.com/playlist/1jkoqs2bZo3mY1RmY6VYG4",
    subLink: "https://open.spotify.com/playlist/1pzez2L3QiQJPQumJEvfLr",
  },
]

export const MUSICARTISTS = [
  {
    title: "chemdoggmillionair",
    summary: "Brooklyn artist that does paintings that a crossover of graffiti, psychedelic line art, and anime characters.",
    mainLink: "https://www.etsy.com/shop/chemdoggmillionaire",
    insta: "https://www.instagram.com/chemdoggmillionaire/",
  },
  {
    title: "Spitter",
    summary: "Local “True Vampyric Metal Punk” band. Devil Master band member overlap",
    mainLink: "https://spiter.bandcamp.com/ ",
    insta: "https://www.instagram.com/spiter666/",
  },
  {
    title: "DIY Tour Network",
    summary: "Hosts info on DIY bands, events, and spaces for Baltimore, Boston, Philly, Pittsburgh and South Central PA. Great site to find local bands or connect with spaces.",
    mainLink: "https://www.diytournetwork.org/ ",
    subLink: "https://docs.google.com/spreadsheets/d/1EixCZ6GOw4WPGODa2CDEPfbAXXyTeveD_qiCfqeLxgY/edit#gid=0",
  },
  {
    title: "Devil Master",
    summary: " ",
  },
  {
    title: "Zorn",
    summary: " "
  },
  {
    title: "NgaNga",
    summary: " "
  },
];

export const PHOTOGRAPHY = [
  {
    title: "20/20 Photo Festival",
    summary: "Annual mid-fall photo fest with exhibitions at a bunch of different galleries.",
    mainLink: "https://www.2020photofestival.org/",
    facebook: "https://www.facebook.com/2020photofestival",
    insta: "https://www.instagram.com/2020photofest/",
    suggestedFollow: "Check site late summer for annual date",
  },
  {
    title: "The Photographic Society of Philadelphia",
    summary: "“The oldest active photography society in the country, started in 1860.” Third Tuesday of each month featuring special guest speakers. Sadly, went from in person to Zoom after pandemic.",
    mainLink: "https://phillyphotosociety.com/events",
    facebook: "https://www.facebook.com/PhotographicSocietyOfPhiladelphia",
    suggestedFollow: "Check site monthly for meeting info",
  },
  {
    title: "TILT Institute for the Contemporary Image",
    summary: "Photography classes, exhibitions, other events, fine art printing & framing, youth outreach, etc.",
    mainLink: "https://tiltinstitute.org/",
    facebook: "https://www.facebook.com/TILTInstitute/",
    insta: "https://www.instagram.com/tiltinstitute/",
    suggestedFollow: "Insta"
  },
];

export const THEATER = [
  {
    title: "Philly Fun Guide Theater List",
    summary: "A nicely selective list of theater playing in Philly.",
    mainLink: "https://www.phillyfunguide.com/theatre",
    suggestedFollow: "Check site calendar monthly",
  },
  {
    title:"Philadelphia Theater",
    summary: "“Your independent guide to the best shows in Philadelphia.“ An indie show guide that covers musicals, plays, stand-up, family shows, dance, and more. Show listings, reviews, previews, highlights by month, etc.",
    mainLink: "https://www.philadelphia-theater.com/",
  },
  {
    title: "FringeArts",
    summary:"“FringeArts is Philadelphia’s home for contemporary performance, presenting progressive, world-class art that expands the imagination and boldly defies expectations. Our programming exposes audiences to genre-defying performances and installations by accomplished and emerging innovators.“ Holds the Fringe Arts Fest each fall. Has a bar as part of building.",
    mainLink: "https://fringearts.com/",
    insta: "https://www.instagram.com/fringearts/",
    facebook: "https://www.facebook.com/phillyfringe/",
  },
  {
    title:"Philly Theater Week",
    mainLink: "https://theatrephiladelphia.org/whats-on-stage/2024-philly-theatre-week",
    summary: "“A celebration of the artists, organizations, and audiences that have made Greater Philadelphia one of the most vibrant theatre regions in the nation.“ Most theaters have 'pay what you can pricing'. Mostly small theater groups, but some large theaters, sometimes, too. An annual blast of theater on the discount. Typically end of March of start of April."
  },
];

export const DATAWIP = [
  {
    title:"DelCo Punk Shows!",
    mainLink: "https://www.facebook.com/groups/1077371709097656",
  },
  {
    title:"Philly DIY Venue Booking Info",
    mainLink: "https://phl-diymusic.weebly.com/diy-venue-booking-info.html",
    summary: "outdated"
  },
  {
    title:"WKDU Philadelphia 91.7 FM",
    mainLink: "https://www.instagram.com/wkdu/",
  },
  {
    title:"Labyrinth Productions",
    mainLink: "https://www.facebook.com/labyrinthproductions/events",
  },
  {
    title:"Rising Tide Collective",
    mainLink: "https://www.instagram.com/risingtidephl/",
    summary: "not to be confused with Rising Tide, the originally Earth First! offshoot climate group"
  },
  {
    title:"Planet Earth",
    mainLink: "https://www.instagram.com/p/CkLu18PJjNj/",
  },
  {
    title:"Cecil Skatepark",
    summary: "https://www.tclf.org/landscapes/cecil-b-moore-station-plaza",
  },
  {
    title:"Temple Community Garden",
    summary: "https://www.templecommunitygarden.com/",
  },
  {
    title:"The Perch ",
    summary: "@ SJU Campus",
    mainLink: "https://www.instagram.com/p/CkwL-BkJZE1/",
  },
  {
    title: "The L",
    insta: "https://www.instagram.com/p/CkwcukgJQuQ/?img_index=3",
  },
  {
    title: "The Urban Shinobi",
    summary: "“Main Antagonist / Villain. 1st Brokage of the Village Hidden In The Jawn. Producer / DJ. Booking : DM. Laser Op. VJ. LD.",
    mainLink: "",
    subLink: "",
    facebook: "",
    insta: "https://www.instagram.com/xurbanshinobix/",
    suggestedFollow: "",
  },
  {
    title: "1516 N Sydenham St., Philadelpiha PA.",
    summary: "",
    mainLink: "",
    subLink: "",
    facebook: "",
    insta: "https://www.instagram.com/p/CnSglvmJ3qU/?img_index=7",
    suggestedFollow: "",
  },
  {
    title: "The Dumpster Out Back",
    summary: "",
    mainLink: "https://www.lifesstillperfect.com/, https://www.instagram.com/p/CoaeYoZpC8m/?img_index=6, https://www.instagram.com/p/CyjFJc1O8KO/?img_index=4",
    subLink: "",
    facebook: "",
    insta: "",
    suggestedFollow: "",
  },
  {
    title: "The Vat",
    insta: "https://www.instagram.com/p/C09tiSzJbNI/",
  },
  {
    title: "Cat Fight",
    insta: "https://www.instagram.com/p/CpAxxtfOlLQ/?img_index=3"
  },
  {
    title: "The Living Room Philly",
    insta: "https://www.instagram.com/p/Cp0qmruJjFw/?img_index=7",
  },
  {
    title: "Bonehaus",
    insta: "https://www.instagram.com/p/CsWZ3A0L9SO/?img_index=3",
  },
  {
    title: "The Gutter",
    insta: "https://www.instagram.com/p/C2Nxuesp0mO/?img_index=2, https://www.instagram.com/p/C17sUm3JSks/?img_index=3",
  },
  {
    title: "funhouse philadelphia",
    summary: "“diy comedy show hosted by lauren ellis. at “the tiny desk of south philly”“",
    insta: "https://www.instagram.com/funhousephl/",
  },
  {
    title: "Flux",
    insta: "https://www.instagram.com/drexelflux/",
  },
  {
    title: "Bell Tower Music",
    insta: "https://www.instagram.com/belltowermusic/",
  },
  {
    title: "Punk Island",
    insta: "https://www.instagram.com/punkislandnyc/",
  },
  {
    title: "Stoop Sounds Philly",
    insta: "https://www.instagram.com/stoop_sounds/",
  },
  {
    title: "Free Fringe Philly",
    insta: "https://www.instagram.com/freefringephilly/",
  },
  {
    title: "Creature Cave",
    insta: "https://www.instagram.com/creaturecave.phl/",
  },
  {
    title: "Penn Treaty Park",
    insta: "https://www.instagram.com/penntreatypark/",
  },
  {
    title: "Flowerz Mag",
    insta: "https://www.instagram.com/flowerz_mag/",
  },
  {
    title: "Philly Music Fest",
    insta: "https://www.instagram.com/phillymusicfest/",
  },
  {
    title: "Mother of Worlds",
    insta: "https://www.instagram.com/motherofworlds/",
  },
  {
    title: "hardcoretoday777",
    insta: "https://www.instagram.com/hardcoretoday777/",
  },
  {
    title: "Death Sculpted Productions",
    insta: "https://www.instagram.com/deathsculptedpros/",
  },
  {
    title: "Harmonie Hall",
    insta: "https://www.instagram.com/harmoniehall_1891/",
  },
  {
    title: "The Groove",
    insta: "https://www.instagram.com/_the_groove_/",
  },
  {
    title: "The El Bar",
    insta: "https://www.instagram.com/theelbarphilly/",
  },
  {
    title: "Riot Nerd",
    insta: "https://www.instagram.com/riotnerdphilly/",
  },
  {
    title: "Grassstains Festival",
    insta: "https://www.instagram.com/grassstainsfestival/",
  },
  {
    title: "Emo Night Philly",
    insta: "https://www.instagram.com/emonightphilly/",
  },
  {
    title: "Goodstone Records",
    insta: "https://www.instagram.com/goodstonerecords/",
  },
  {
    title: "The Rocker",
    insta: "https://www.instagram.com/therockershow/",
  },
  {
    title: "antiflowershowmovement",
    insta: "https://www.instagram.com/antiflowershowmovement/",
  },
  {
    title: "Zook Productions",
    insta: "https://www.instagram.com/zookproductions/",
  },
  {
    title: "Toxic Femme",
    insta: "https://www.instagram.com/toxicfemme666/",
  },
  {
    title: "T4THC",
    insta: "https://www.instagram.com/t4thcbakery/",
  },
  {
    title: "Philly Music Scene",
    insta: "https://www.instagram.com/phillymusicscene/",
  },
  {
    title: "Coalition for Black Trans Economic Liberation",
    insta: "the Transcendent True-Faith Church of dobler",
  },
  {
    title: "Vine Collective",
    insta: "https://www.instagram.com/vinecollectivephl/",
  },
  {
    title: "Plaze Music",
    insta: "https://www.instagram.com/plaze_music/",
  },
  {
    title: "The Baltic Hall,",
    insta: "https://www.instagram.com/p/C72PWizJYc2/?img_index=3, https://www.instagram.com/p/C7VKkTEJcfx/?img_index=6",
  },
  {
    title: "2444 W. Ritner",
    insta: "https://www.instagram.com/p/C72OpfFp6MB/?img_index=6",
  },
  {
    title: "Ulana's",
    insta: "https://www.yelp.com/biz/ulanas-philadelphia",
  },
  {
    title: "Brittany's House",
    insta: "https://www.instagram.com/p/C54Io36JWC6/?img_index=3",
  },
  {
    title: "Bilge Water Productions",
    insta: "https://www.instagram.com/bilgewaterproductions/",
  },
  {
    title: "JM Sound and Audio",
    insta: "https://www.instagram.com/bilgewaterproductions/",
  },
  {
    title: "Limit.Nine",
    insta: "https://www.instagram.com/p/C1X2vOmJgev/?img_index=1",
  },
  {
    title: "Paine's Park",
    insta: "https://skatephilly.org/parks/paines-park/",
  },
  {
    title: "4811 Chester Ave.",
    insta: "https://www.instagram.com/p/Cwkx0m4Lmli/?img_index=1",
  },
  {
    title: "Launderette Records",
    insta: "https://www.instagram.com/launderette.records/",
  },
  {
    title: "Fortess Records",
    insta: "https://www.instagram.com/fortress.records/",
  },
  {
    title: "SANCTUA xr",
    insta: "https://www.instagram.com/sanctuaxr/",
  },
  {
    title: "Dahlak Paradise",
    insta: "https://www.instagram.com/dahlak_paradise/",
  },
  {
    title: "In•dex",
    insta: "https://www.instagram.com/index.0001/",
  },
  {
    title: "Zembo Temple of Skate & Design",
    insta: "https://www.instagram.com/zemboskatedesign/",
  },
  {
    title: "Tape and C.D. Records",
    insta: "https://www.instagram.com/tapeandcdrecords/",
  },
  {
    title: "Festival Events List",
    insta: "https://www.instagram.com/foodieflavors101/",
  },
  {
    title: "Rittenhouse Filmworks",
    insta: "https://rittenhousefilmworks.com/",
  },
  {
    title: "Headroom Studios",
    insta: "https://www.instagram.com/headroomphiladelphia/",
  },
  {
    title: "Penn Food and Wellness Collaborative farm",
    insta: "https://www.facebook.com/share/p/p4iyYwTc4TAEy2o4/",
  },
  {
    title: "Neighborhood Food Farms",
    insta: "https://www.facebook.com/share/p/BixDSBGKhLxqE8hE/",
  },
  {
    title: "Pink House",
    summary: "inactive?",
    insta: "https://www.instagram.com/pink_house_philly/",
  },
  {
    title: "Dance Off Productions",
    insta: "https://www.instagram.com/dance_off_productions/",
  },
  {
    title: "hardcoretoday777",
    insta: "https://www.instagram.com/hardcoretoday777/",
  },
  {
    title: "",
    insta: "https://www.instagram.com/phillymusicfest/",
  },
  {
    title: "",
    summary: "inactive?",
    insta: "https://www.instagram.com/lustdustphl/",
  },
  {
    title: "https://www.instagram.com/corridor_phl/",
    insta: "",
  },
  {
    title: "",
    summary: "inactive?",
    insta: "https://www.instagram.com/the_allegory_philly/",
  },
  {
    title: "",
    insta: "https://www.instagram.com/thelastarizona/",
  },
  {
    title: "PhillyJerseyScene",
    summary: "“Reposting awesome local shows in philly + jersey (occasionally ny) 😛👽👾🛸💥🌈🪩💞“. One post per day that has shows. Since Oct 15th, 2024. Innactive?",
    insta: "https://www.instagram.com/phillyjerseyscene/"
  },
  {
    title:"Krampus Fest and Night Market",
    mainLink: "https://www.mediaartscouncil.org/calendar/h479ms7eeu510ims4szwuz6yupgysa",
  },
  {
    title:"Julboard Dinner",
    mainLink: "https://www.americanswedish.org/events/julbord-dinner",
  },
  {
    title:"Santa Lucia & Christmas Market",
    mainLink: "https://www.americanswedish.org/events/lucia-christmas-market-2",
  },
  {
    title:"The Resource Exchange",
    mainLink: "https://theresourceexchange.org/",
  },
  {
    title: "The Mann",
    mainLink: "https://manncenter.org/events"
  },
  {
    title: "West Kensington Ministry"
  },
  {
    title: "Firehouse Warehouse"
  },
  {
    title: "The Fallser Club"
  },
  {
    title: "Minimal Chaos Collective Annul April 20 Potluck"
  }
];

export const TEMP = [
  {
    title: "",
    summary: "",
    mainLink: "",
    subLink: "",
    facebook: "",
    insta: "",
    suggestedFollow: "",
  },
];
