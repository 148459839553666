export const VIDEOSLOCALINFO = [
  {
    "filename": "LOCAL_BAND_DEVIL MASTER - Acid Black Mass (Official Music Video) i-pqnibwdKw",
    "title": "Devil Master - Acid Black Mass",
    "category": "Band",
    "mainLink": "https://devilmaster.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.facebook.com/devilisyourmaster",
    "videographer": "@Erica Frevel",
  },
  {
    "filename": "LOCAL_BAND_Skullovich (St. Thrashricks Festival, Metro Gallery, Baltimore, Maryland, March 9th, 2024) [prRMf48IqO0]",
    "title": "Skullovich",
    "category": "Band",
    "mainLink": "https://skullovich.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/skullovich_official/",
    "videographer": "@The Renaissance Blann",
  },
  {
    "filename": "LOCAL_BAND_Timesup [McGe6Z5WFgg]",
    "title": "OK Buddy - Timesup",
    "category": "Band",
    "mainLink": "https://okbuddy.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/okbuddy.pa/",
  },
  {
    "filename": "LOCAL_BAND_Witching - Eschaton (Official Music Video) [20noD3q9mZg]",
    "title": "Witching - Eschaton",
    "category": "Band",
    "mainLink": "https://witching.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/witching_philly/",
  },
  {
    "filename": "LOCAL_BAND_Mannequin Pussy - ＂I Got Heaven＂ [EvgYIXc4JDA]",
    "title": "Mannequin Pussy - I Got Heaven",
    "category": "Band",
    "mainLink": "https://mannequinpussy.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/mannequinpussy/",
  },
  {
    "filename": "LOCAL_BAND_NECROSEXUAL ＂DEAD SEXY＂ Music Video [OHV4Cz0NRuk]",
    "title": "Necro Sexual - Dead Sexy",
    "category": "Band",
    "mainLink": "https://necrosexual.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/thenecrosexual/",
  },
  {
    "filename": "LOCAL_BAND_Doused - Hybrid Moments [fr7TBfCNccM]",
    "title": "Doused - Hybrid Moments",
    "category": "Band",
    "mainLink": "https://dousedphl.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/dousedphl",
  },
  {
    "filename": "LOCAL_BAND_MORGUL BLADE - Heavy Metal Wraiths (OFFICIAL VIDEO) [MCnhn4kbst8]",
    "title": "Morgul Blade - Heavy Metal Wraiths",
    "category": "Band",
    "mainLink": "https://morgulblade.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/morgul_blade_hm/",
  },
  {
    "filename": "LOCAL_BAND_Knuckle Deep - Gaslight LIVE! At The Crawlspace (9.18.21) [lW39elk-NcQ]",
    "title": "Knuckle Deep - Gaslight",
    "category": "Band",
    "mainLink": "https://knuckledeep.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/knuckledeeptv/",
  },
  {
    "filename": "LOCAL_FILM_PIZZA PARTY MASSACRE - Official Trailer [93QAvv8mzJ4]",
    "category": "Film",
    "title": "Pizza Party Massacre (2024)",
    "mainLink": "https://www.sickslicestudios.com/",
    "mainLinkType": "Studio",
    "social": "https://www.instagram.com/luthersmidfrightsnack/",
  },
  {
    "filename": "LOCAL_FILM_HeBGB TV OFFICIAL TRAILER [xcYsf_g8ju8]",
    "category": "Film",
    "title": "HeBGB TV (2022)",
    "mainLink": "https://www.hebgbtv.com/",
    "mainLinkType": "Studio",
    "social": "https://www.instagram.com/birthdayjake/",
  },
  {
    "filename": "LOCAL_FILM_A Corpse For Christmas - Nice Trailer (blurred) - by Brewce Longo, starring Kasper Meltedhair [4LQETGyt5b4]",
    "title": "A Corpse for Christmas (2023)",
    "category": "Film",
    "mainLink": "https://bloodsickproductions.bigcartel.com/",
    "mainLinkType": "Studio",
    "social": "https://www.instagram.com/bloodsickproductions/",
  },
  {
    "filename": "LOCAL_FILM_Coven of the Black Cube - 2024 Trailer - from Blood Sick Productions [8PvuhB5ii9g]",
    "title": "Coven of the Black Cube (2024)",
    "category": "Film",
    "mainLink": "https://bloodsickproductions.bigcartel.com/",
    "mainLinkType": "Studio",
    "social": "https://www.instagram.com/bloodsickproductions/",
  },  
  {
    "filename": "LOCAL_FILM_CADDY HACK  - OFFICIAL TRAILER [r5ekIlimxxw]",
    "title": "Caddy Hack (2023)",
    "category": "Film",
    "mainLink": "https://www.wildeyereleasing.com/",
    "mainLinkType": "Distributor",
    "social": "https://www.instagram.com/wildeyemovies/",
  },  
  {
    "filename": "LOCAL_FESTIVAL_PUFF9 Official Trailer (2024) [1TdslrZI64Y]",
    "title": "Philadelphia Unnamed Film Festival",
    "category": "Underground Film Festival",
    "mainLink": "https://www.phillyunnamed.org/",
    "mainLinkType": "Festival",
    "social": "https://www.instagram.com/phillyunnamed/",
  },  
  {
    "filename": "LOCAL_BAND_Skullpresser - Positions of Power (Official Video) [W3itMsjiXzs]",
    "title": "SKULLPRESSER - Positions of Power",
    "category": "Band",
    "mainLink": "https://skullpresser.bandcamp.com",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/skullpresser",
  },  
  {
    "filename": "LOCAL_BAND_Blank Spell (Live in Philadelphia, PA @ The First Unitarian Church - 02.24.2024) [hJtkdjXw41k]",
    "title": "Blank Spell - Red Teeth",
    "category": "Band",
    "mainLink": "https://blankspell.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "videographer": "@Miserly_Portions",  
  },
  {
    "filename": "LOCAL_FILM_Porch Pirate Official Trailer [70V_Z1Plfdw]",
    "title": "Porch Pirate (2024)",
    "category": "Film",
    "mainLink": "https://babygoblinproductions.com",
    "mainLinkType": "Studio", 
    "social": "https://www.instagram.com/babygoblinproductions",
  }, 
  {
    "filename": "LOCAL_BAND_[hate5six] Zorn - February 24, 2024 [wX5DRoMBciw]",
    "title": "Zorn @ FU Church 02/24/2024",
    "category": "Band",
    "mainLink": "https://zornphilly.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/zorn_philly/",
    "videographer": "@hate5six",  
  },
  {
    "filename": "LOCAL_BAND_SPITER , ＂LIVING NIGHTMARE＂ [XZi-L4nb_g0]",
    "title": "Spiter - Living Nightmare",
    "category": "Band",
    "mainLink": "https://spiter.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/spiter666/",
    "videographer": "@luthersmidfrightsnack",
  },   
  {
    "filename": "LOCAL_BAND_Bogside Sniper Squadron - Live 2⧸9⧸24 [g_4wt87sVgo]",
    "title": "Bogside Sniper Squadron",
    "category": "Band",
    "mainLink": "https://bogsidesnipersquadron.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/batmagick/",
    "videographer": "@orgasmedia"
  },
  {
    "filename": "LOCAL_BAND_Cape of Bats [mwUEFO_DfQA]",
    "title": "Cape of Bats Circa 2013 @ ???",
    "category": "Band",
    "mainLink": "https://capeofbats.bandcamp.com/music",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/batmagick",
    "videographer": "@sydvysh729"
  },
  {
    "filename": "LOCAL_BAND_Bán Sidhe Dogs - 04 To Some I Have Talked With By The Fire",
    "title": "Bán Sidhe Dogs -  To Some I Have Talked With By The Fire",
    "category": "Band",
    "mainLink": "https://bansidhedogs.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/batmagick",
  },
  {
    "filename": "LOCAL_BAND_Unholy Altar  - Black Winds Of Nocturnal Dismay (Music Video) [CE3dalVRy5M]",
    "title": "Unholy Altar- Black Winds Of Nocturnal Dismay",
    "category": "Band",
    "mainLink": "https://unholyaltar666.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/unholy.altar",
  },
  {
    "filename": "LOCAL_BAND_GLORIOUS DESCENT [jYJ3nvQniGc]",
    "title": "Glorious Descent in The Meatlocker, July 2023",
    "category": "Band",
    "mainLink": "https://gloriousdescent.bandcamp.com/",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/batmagick",
    "videographer": "@MEATLOCKERTV"
  },
  {
    "filename": "LOCAL_BAND__Tin_Nanny 04 Sun On The Moon",
    "title": "Tin Nanny - Sun On The Moon",
    "category": "Band",
    "mainLink": "https://tinnanny.bandcamp.com",
    "mainLinkType": "Bandcamp",
    "social": "https://www.instagram.com/tin_nanny",
  }    
]